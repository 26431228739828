import * as React from 'react';
import { IndeterminentProgress, ProgressButton, SnackBar, TagsInput } from "../../components";
import { makeStyles, Paper, TextField } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { toast } from "react-toastify"

import { editProfileURL } from '../../../services/some';
import { ChipData } from "../../components/tags-input/TagsInput";
import { editAccount } from '../../../services/account';
import { IContextProps, DrawerContext } from '../../context/ui-context/DrawerContext'
import history from "../../utils/history";
import { RouteComponentProps } from "react-router";
import { IContextProps as IAccContext, AccountsContext } from "../../context/account/AccountContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexFlow: 'column wrap',
      padding: theme.spacing(0.5),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40%',
    },
    heading: {
      marginLeft: theme.spacing(1),
    },
    control: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '20px'
    }
  }),
);

interface IProps extends RouteComponentProps<{ id: string }> {
}

export const EditThreads = (props: IProps) => {
  const { match: { params } } = props;
  if (!params.id) {
    history.push('/');
  }
  const { setActivePage } = React.useContext<IContextProps>(DrawerContext);
  setActivePage('threads-edit', 'Edit Threads account');

  const { fetchSingleAccount, singleAccount, loading: loadingSingle } = React.useContext<IAccContext>(AccountsContext);
  const formRef = React.useRef<any>();
  const defaultState = {
    url: '',
    userId: '',
    accessToken: '',
    username: '',
    uniqueName: '',
  };

  const [formState, setFormState] = React.useState<any>(defaultState);
  const [chips, setChips] = React.useState<{ tags: ChipData[] }>({ tags: [] });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [hashTags] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const classes = useStyles();

  React.useEffect(() => {
    if (singleAccount) {
      console.log('single acc is===', singleAccount);
      const {
        url,
        userId,
        accessToken,
        iatas,
        username,
        uniqueName,
        hashTags = '',
        // branchLink = ''
        trelloCardId,
      }: any = singleAccount;
      const tags: ChipData[] = [];
      if (iatas) {
        const _iatas: any[] = iatas;
        _iatas.forEach((item, index) => {
          tags.push({ key: index, label: item })
        });
      }
      setChips({ tags });
      setFormState({
        id: singleAccount.id,
        url: url ? url : "",
        trelloCardId: trelloCardId ? trelloCardId : "",
        userId,
        username,
        uniqueName,
        hashTags,
        accessToken,
      });
    }
  }, [singleAccount]);

  React.useEffect(() => {
    fetchSingleAccount('threads', params.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTags = (tags: ChipData[]) => {
    setChips({ tags });
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    setFormState({ ...formState, [e.currentTarget.name]: e.currentTarget.value });
  }


  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    // editUrlOnSomeTracker();
    const tags: string[] = [];
    chips.tags.map(tag => tags.push(tag.label));
    const args: any = {
      platform: 'threads',
      iatas: tags,
      url: formState.url ? formState.url : '',
      ...formState
    };

    editAccount(args)
      .then(({ status }) => {
        if (status) {
          setMessage('Threads account updated Successfully');
          setShowMessage(true);
        }
      })
      .catch((error) => {
        setMessage('Something went wrong');
        setShowMessage(true);
      })
      .finally(() => setLoading(false))
  }

  return (
    <Paper className={classes.root}>
      <h3 className={classes.heading}>Edit threads account</h3>
      {!!(loading || loadingSingle) && <IndeterminentProgress />}
      <form onSubmit={handleSubmit} ref={formRef} noValidate={true}>
        <TagsInput
          required={!(chips && chips.tags && chips.tags.length)}
          onChange={onChangeTags}
          tags={chips.tags}
        />
        <TextField
          autoComplete={"off"}
          label="URL"
          className={classes.textField}
          helperText="Enter url"
          margin="normal"
          name="url"
          type="url"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.url}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Unique Name"
          className={classes.textField}
          helperText="Enter Unique Name"
          margin="normal"
          name="uniqueName"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.uniqueName}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Username"
          className={classes.textField}
          helperText="Enter username"
          margin="normal"
          name="username"
          onChange={handleChange}
          autoFocus={true}
          value={formState.username}
          disabled={loading}
          required
        />
        <TextField
          autoComplete={"off"}
          label="User Id"
          className={classes.textField}
          helperText="Enter User Id"
          margin="normal"
          name="userId"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.userId}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Access Token"
          className={classes.textField}
          helperText="Enter access token. You can get Access Token and Access Token Secret here: http://159.89.188.166:8080/authorize_twitter"
          margin="normal"
          name="accessToken"
          type="text"
          onChange={handleChange}
          value={formState.accessToken}
          autoFocus={true}
          disabled={loading}
        />
        <TextField
          autoComplete={"off"}
          // label="Hash Tags"
          className={classes.textField}
          helperText="Enter hash tags by comma separated"
          margin="normal"
          name="hashTags"
          type="text"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.hashTags}
        />
        <TextField
          autoComplete={"off"}
          // label="Trello Card Id"
          className={classes.textField}
          helperText="Trello Card Id"
          margin="normal"
          name="trelloCardId"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.trelloCardId}
          required
        />
        <div className={classes.control}>
          <ProgressButton title={"Save"} type={"submit"} loading={loading} />
        </div>
      </form>
      {!!showMessage && (
        <SnackBar message={message} onClose={() => setShowMessage(false)} />
      )}
    </Paper>
  );
};
