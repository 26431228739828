import * as React from 'react';
import {Route, Redirect} from 'react-router-dom';

const RedirectToMain = () => {
  return (
    <Redirect to="/dashboard"/>
  );
}


export const renderRoutes =
  (routes: SideBarItem[], opts: { deep: boolean, authenticated: boolean },) => {
    const routesComponent: any[] = [];
    routes.map((route: SideBarItem) => {
      if (route.children && route.children.length) {
        route.children.map((item: SiderBarItemChild) => {
          if (item.component) routesComponent.push(
            <Route
              exact
              key={item.slug}
              path={item.route}
              component={item.component}/>
          );
          return null;
        })
      } else {
        if (route.component) routesComponent.push(
          <Route
            exact
            key={route.slug}
            path={route.route}
            component={route.component}/>
        )
      }
      return null;
    });

    routesComponent.push(
      <Route
        component={RedirectToMain}
        key={'four-o-four'}
      />
    );

    return routesComponent;
  };

