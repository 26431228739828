import * as React from 'react';
import { IndeterminentProgress, ProgressButton, SnackBar, TagsInput } from "../../components";
import { makeStyles, Card, CardContent, Paper, TextField } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import moment from 'moment';

import { createSoMeImage } from '../../../services';
import { IContextProps, DrawerContext } from '../../context/ui-context/DrawerContext'

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexFlow: 'column wrap',
    padding: theme.spacing(0.5),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width: '40%',
  },
  heading: {
    marginLeft: theme.spacing(1),
  },
  control: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '20px'
  },
  card: {
    maxWidth: 300,
  },
  media: {
    height: 300
  }
}),
);

interface IProps {
}

export const CreateDealImage = (props: IProps) => {
  const { setActivePage } = React.useContext<IContextProps>(DrawerContext);
  setActivePage('deal-image', 'Create Deal Image');
  const formRef: any = React.useRef<any>();
  const today = moment().format('YYYY-MM-DD')
  const [formState, setFormState] = React.useState<any>({
    departDate: today,
    returnDate: today
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [screenshot, setScreenshot] = React.useState<string>('');
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const classes = useStyles();

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    e.preventDefault();
    setFormState({ ...formState, [e.currentTarget.name]: String(e.currentTarget.value).toUpperCase() });
  }

  function showSnack (message: string) {
    setMessage(message)
    setShowMessage(true)
  }

  const hasErrors = () => {
    const {departDate, returnDate} = formState

    console.log(formState)

    if (!formState.origin || !formState.destination) {
      showSnack('Origin and Destination is required')
      return true
    }

    if (!formState.priceString) {
      showSnack('Price is required')
      return true
    }

    if (moment(departDate).valueOf() > moment(returnDate).valueOf()) {
      showSnack('Depart date should be before Return Date')
      return true
    }

    return false
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const error: boolean = hasErrors()
    if (error) {
      return
    }

    setLoading(true);
    setScreenshot('');

    createSoMeImage(formState)
    .then(({ status, data }) => {
      if (status) {
        showSnack('FD SoMe image created');
        if (data.screenshot) {
          setScreenshot(data.screenshot)
        }
      }
    })
    .catch((error) => {
      showSnack('Something went wrong');
    })
    .finally(() => setLoading(false))
  }

  return (
    <Paper className={classes.root}>
    <h3 className={classes.heading}>Create SoMe image for Deal</h3>
    {!!loading && <IndeterminentProgress />}
    <form onSubmit={handleSubmit} ref={formRef} noValidate={true}>
    <TextField
    autoComplete={'off'}
    label="Origin"
    className={classes.textField}
    margin="normal"
    name="origin"
    type="text"
    onChange={handleChange}
    autoFocus={true}
    disabled={loading}
    required
    value={formState.origin || ""}
    />
    <TextField
    autoComplete={'off'}
    label="Destination"
    className={classes.textField}
    margin="normal"
    name="destination"
    type="text"
    onChange={handleChange}
    disabled={loading}
    required
    value={formState.destination || ""}
    />
    <TextField
    name="departDate"
    label="Departure"
    type="date"
    defaultValue={formState.departDate}
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
    onChange={handleChange}
    required
    />
    <TextField
    name="returnDate"
    label="Return"
    type="date"
    defaultValue={formState.returnDate}
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
    onChange={handleChange}
    required
    />
    <TextField
    autoComplete={'off'}
    label="Price"
    className={classes.textField}
    helperText="Enter price with Currency symbol (Eg: $420)"
    margin="normal"
    name="priceString"
    type="text"
    onChange={handleChange}
    disabled={loading}
    />
    {/*
    <TextField
    autoComplete={'off'}
    label="Background URL"
    className={classes.textField}
    helperText="Enter optional background url"
    margin="normal"
    name="url"
    type="url"
    onChange={handleChange}
    disabled={loading}
    />
    */}

    <div className={classes.control}>
    <ProgressButton
    title={'Create'}
    type={'submit'}
    loading={loading}
    />
    </div>
    </form>
    {!!screenshot && <Card className={classes.card}>
    <CardContent className={classes.media}>
      <img src={screenshot} className={classes.media}/>
      </CardContent>
      </Card>
      }
    {!!showMessage && <SnackBar message={message} onClose={() => !showMessage && setShowMessage(false)} />}
    </Paper>
    );
  };
