import * as React from 'react';
import { IndeterminentProgress, ISwitch, ProgressButton, SnackBar, TagsInput } from "../../components";
import { FormControl, Input, InputLabel, makeStyles, MenuItem, Paper, Select, TextField } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { toast } from "react-toastify"

import { ChipData } from "../../components/tags-input/TagsInput";
import { createAccount } from '../../../services/account';
import { addProfileURL } from '../../../services/some';
import { IContextProps, DrawerContext } from '../../context/ui-context/DrawerContext'
import { AccountsContext, IContextProps as IAccountsContext } from "../../context/account/AccountContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      marginLeft: '300px;'
    },
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexFlow: 'column wrap',
      padding: theme.spacing(0.5),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40%',
    },
    heading: {
      marginLeft: theme.spacing(1),
    },
     formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    select: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
    },
    control: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '20px'
    },
    error: {
      color: 'red',
      padding: '5px',
    }
  }),
);

interface IProps {
}

export const CreatePostNews = (props: IProps) => {
  const { setActivePage } = React.useContext<IContextProps>(DrawerContext);
  const { addSingleAccountToList } = React.useContext<IAccountsContext>(AccountsContext);
  setActivePage('postNews-add', 'Add PostNews account');
  const [chips, setChips] = React.useState<{ tags: ChipData[] }>({ tags: [] });
  const formRef: any = React.useRef<any>();
  const [formState, setFormState] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [nameError, setnameError] = React.useState<string>('');
  const [hashTags] = React.useState<string>('');
  const [branchLink] = React.useState<string>('');
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [accountStatus, setAccountStatus] = React.useState<boolean>(false);
  const classes = useStyles();

  const onChangeTags = (tags: ChipData[]) => {
    setChips({ tags });
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    e.preventDefault();
    setFormState({ ...formState, [e.currentTarget.name]: e.currentTarget.value });
    if (error) setError('');
    validate();
  }

   function handleSelect(
     event: React.ChangeEvent<{ name?: string; value: unknown }>
   ) {
     console.log("event is", event);
     setFormState({
       ...formState,
       [event.target.name as string]: event.target.value,
     });
   }

  function validate() {
    const regexp = /\s/g
    if (regexp.test(formState.adpName) || regexp.test(formState.username)) {
      setnameError("Whitespace is not allowed");
    } else {
      setnameError("");
    }
  }

  function onChangeAccountSts() {
    setAccountStatus(!accountStatus)
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
     if (
       !formState.email ||
       !formState.password ||
       !formState.email.length ||
       !formState.password.length || 
       !formState.username ||
       !formState.username.length
     ) {
       setError("Missing Fields");
       return false;
     }
    setLoading(true);
    const tags: string[] = [];
    chips.tags.map(tag => tags.push(tag.label));
    const args: any = {
      platform: 'postNews',
      isActive: accountStatus,
      iatas: tags,
      branchLink,
      hashTags,
      ...formState
    };

    createAccount(args)
      .then(({ data }) => {
        if (data.error) {
          setError(data.error)
        } else if (data) {
          addSingleAccountToList({ platform: "postNews", account: data });
          setMessage('PostNews account Saved Successfully');
          setShowMessage(true);
          formRef.current.reset();
        }
      })
      .catch((error) => {
        const { response: { data } } = error;
        if (data) {
          setError(data.error)
        } else {
          setMessage('Something went wrong');
          setShowMessage(true);
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <Paper className={classes.root}>
      <h3 className={classes.heading}>Add PostNews account</h3>
      {!!loading && <IndeterminentProgress />}
      <form onSubmit={handleSubmit} ref={formRef} noValidate={true}>
        <TagsInput onChange={onChangeTags} tags={chips.tags} />
        <TextField
          autoComplete={"off"}
          label="Unique Name"
          className={classes.textField}
          helperText={
            !!nameError ? "Whitespaces are not allowed" : "Enter unique name"
          }
          margin="normal"
          name="uniqueName"
          type="text"
          // error={!!nameError}
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Username"
          className={classes.textField}
          helperText={
            !!nameError ? "Whitespaces are not allowed" : "Enter username"
          }
          margin="normal"
          name="username"
          type="text"
          // error={!!nameError}
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Email"
          className={classes.textField}
          margin="normal"
          name="email"
          type="email"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Password"
          className={classes.textField}
          margin="normal"
          name="password"
          type="text"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        />

        <TextField
          autoComplete={"off"}
          label="Hash Tags"
          className={classes.textField}
          margin="normal"
          placeholder="Enter hash tags by comma separated"
          name="hashTags"
          type="text"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Branch Link"
          className={classes.textField}
          margin="normal"
          placeholder="Enter Branch Link"
          name="branchLink"
          type="text"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Trello Card Id"
          className={classes.textField}
          margin="normal"
          placeholder="Trello Card Id"
          name="trelloCardId"
          type="text"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        />

        <div className={classes.checkbox}>
          <ISwitch
            checked={accountStatus}
            id={1}
            onChange={onChangeAccountSts}
          />
        </div>
        {!!error && <p className={classes.error}>{error}</p>}
        <div className={classes.control}>
          <ProgressButton
            title={"Save"}
            type={"submit"}
            loading={loading}
            disabled={loading || !!error || !!nameError}
          />
        </div>
      </form>
      {!!showMessage && (
        <SnackBar message={message} onClose={() => setShowMessage(false)} />
      )}
    </Paper>
  );
};
