import * as React from 'react';
import {Switch} from 'react-router-dom';
import {renderRoutes} from '../../utils/render-routes/render-routes.util';
import {DrawerItems} from '../../context/ui-context/DrawerItems'

export const Main = () => {
  return (
    <Switch>
      {renderRoutes(DrawerItems, {authenticated: true, deep: false})}
    </Switch>
  );
};