import * as React from 'react';
import {createStyles, Theme} from "@material-ui/core/styles";
import {makeStyles} from "@material-ui/core";
import {CustomSwitch, IndeterminentProgress} from "../../../components";
import {Card} from "./Card";
import {getInstagramPendingDeals, updatedInstaDealStatus} from "../../../../services/insta-deals";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: 'calc(100vh - 80px)',
      margin: '10px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: 'auto',
      minWidth: '500px',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0 39px',
    },
    body: {
      display: 'flex',
      overflow: 'auto',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    empty: {
      color: 'red',
      display: 'flex',
      justifyContent: 'center',
      minWidth: '500px',
    },
  }),
);

interface IProps {
}


export const ManualPost: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [showPosted, setShowPosted] = React.useState<boolean>(true);
  const [showNotPosted, setShowNotPosted] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [deals, setDeals] = React.useState<any[]>([]);

  function toggleShowPosted() {
    setShowPosted(!showPosted);
  }

  function toggleShowNotPosted() {
    setShowNotPosted(!showNotPosted);
  }

  function onTogglePosted(id?: any) {
    const acc = deals.find(item => item.deal_id === id);
    const dealz = deals.map((item) => {
      if (item.deal_id === id) {
        return {...item, status: false}
      }
      return item;
    })
    updatedInstaDealStatus({adpName: acc.adpName, deal_id: acc.deal_id, iatas: acc.iatas})
    setDeals(dealz)
  }

  React.useEffect(() => {
    setLoading(true)
    getInstagramPendingDeals()
      .then(({data}) => {
        const {resp}: { resp: any[] } = data;
        if (resp && resp.length) {
          const dealz = resp.map((item) => {
            return {...item};
          })
          setDeals(dealz)
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [])

  function dealsList() {
    let dealz = deals;
    console.log(showNotPosted, 'h', dealz)
      dealz = dealz.filter(item => {
        if (showNotPosted) {
          return item.status
        }

        return !item.status
      })

    return dealz;
  }

  return (
    <div className={classes.root}>
      {!!loading && <IndeterminentProgress/>}
      <div className={classes.container}>
        <div className={classes.header}>
          {/*<div className={classes.body}>*/}
            {/*<CustomSwitch checked={showPosted} onChange={toggleShowPosted}/>*/}
            {/*<p>Show Posted</p>*/}
          {/*</div>*/}
          <div className={classes.body}>
            <CustomSwitch checked={showNotPosted} onChange={toggleShowNotPosted}/>
            <p>Show Not Posted</p>
          </div>
        </div>
        <div className={classes.body}>
          <div className={classes.list}>
            {!!(deals && deals.length) && dealsList().map((deal) => {
              return <Card onTogglePosted={onTogglePosted} deal={deal}/>
            })}
            {!(deals && deals.length) && <div className={classes.empty}>
              <p>No pending posts found for any account</p>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
};
