import * as React from 'react';
import {getIssues, resolveIssue} from '../../../services';

export interface IContextProps {
  fetchIssues(platform: AccountType, accid?: string): void;

  resolveIssues(platform: AccountType, type: IssueType, id: string): void;

  issues?: Issues;
  loadingIssues: boolean;
  resolvingIssue: boolean;
}

type AccountType =
  | 'reddit' | 'twitter' | 'instagram' | 'facebook' | 'postNews' | 'threads';

type IssueType = 'pending' | 'failure' | 'resolved';

export const IssuesContext = React.createContext({} as IContextProps);

export const IssuesContextProvider = ({children}: any) => {

  const [issues, setIssues] = React.useState<Issues>();
  const [loadingIssues, setLoadingIssues] = React.useState<boolean>(false);
  const [resolvingIssue, setResolvingIssue] = React.useState<boolean>(false);

  function fetchIssues(platform: AccountType, accid?: string) {
    setLoadingIssues(true);
    getIssues({platform, accid})
      .then(({data}) => {
        if (data) {
          setIssues(data)
        }
      })
      .catch(console.log)
      .finally(() => setLoadingIssues(false))
  }

  function resolveIssues(platform: AccountType, type: IssueType, id: string) {
    resolveIssue({platform, type, id})
      .then(({data, status}) => {
        if (status === 200 && issues) {
          fetchIssues(platform);
        }
      })
      .catch(console.log)
      .finally(() => setResolvingIssue(false));
  }

  const defaultContext = {
    fetchIssues,
    loadingIssues,
    issues,
    resolvingIssue,
    resolveIssues
  };

  return (
    <IssuesContext.Provider value={defaultContext}>
      {children}
    </IssuesContext.Provider>
  );
};