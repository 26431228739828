import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch, {SwitchClassKey, SwitchProps} from '@material-ui/core/Switch';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

export const CustomSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({classes, ...props}: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

interface IProps {
  checked: boolean;
  id?: any;

  onChange(data: { id: any, checked: boolean }): void;
}

export const ISwitch: React.FC<IProps> = React.memo((props) => {
  const {checked, onChange, id} = props;

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange({id, checked: !checked});
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <CustomSwitch
            checked={checked}
            onChange={handleChange('checkedB')}
            value="checkedB"
          />
        }
        label={<p style={{whiteSpace: 'nowrap'}}>
          {checked ? 'Active' : 'In Active'}
          </p>}
      />
    </FormGroup>
  );
})