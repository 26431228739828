import React from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  makeStyles,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {AppContext} from "../../context/AppContext";
import {IndeterminentProgress} from "../../components";

function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Built with love by the '}
      <Link color="inherit" href="https://material-ui.com/">
        Material-UI
      </Link>
      {' team.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: 'red',
  },
}));

export const Login = () => {
  const classes = useStyles();
  const {authenticate} = React.useContext(AppContext);
  const [username, setUsername] = React.useState<string | undefined>();
  const [password, setPassword] = React.useState<string | undefined>();
  const [usernameError, setUsernameError] = React.useState<boolean>(false);
  const [pwdError, setPwdError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [authError, setAuthError] = React.useState<string>('');

  const validate = function () {
    let isValid = true;

    if (!username) {
      setUsernameError(true);
      isValid = false;
    } else {
      setUsernameError(false);
    }

    if (!password) {
      setPwdError(true);
      isValid = false;
    } else {
      setPwdError(false);
    }
    return isValid;
  }

  const handleSubmit = function () {
    if (validate()) {

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        const auth = authenticate({username: username ? username : '', password: password ? password : ''});
        if (auth) {
          setAuthError(auth);
        }
      }, 3000);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      {loading && <IndeterminentProgress/>}
      <CssBaseline/>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="text"
            error={usernameError}
            onChange={event => setUsername(event.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={pwdError}
            onChange={event => setPassword(event.target.value)}
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary"/>}
            label="Remember me"
          />
          <br/>
          {authError && <p className={classes.error}>{authError}</p>}
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <MadeWithLove/>
      </Box>
    </Container>
  );
}