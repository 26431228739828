import * as React from 'react';
import {DrawerItems} from './DrawerItems';
import history from '../../utils/history';

export interface IContextProps {
  drawerWidth: number;
  isDrawerOpen: boolean;
  toggleDrawer: Function;
  sideBarItems: SideBarItem[];
  onRouteChange: any;
  activePage: string;
  pageTitle: string;

  setActivePage(slug: string, title: string): void;
}

export const DrawerContext = React.createContext({} as IContextProps);

export const DrawerContextProvider = ({children}: any) => {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [activePage, setActivePageSlug] = React.useState('');
  const [pageTitle, setPageTitle] = React.useState('');
  const drawerWidth: number = 400;

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const onRouteChange = (route: string) => {
    setDrawerOpen(false);
    if (route) history.push(route);
  };

  function setActivePage(slug: string, title: string) {
    setActivePageSlug(slug);
    setPageTitle(title);
  }

  const defaultContext = {
    isDrawerOpen,
    toggleDrawer,
    drawerWidth,
    onRouteChange,
    activePage,
    setActivePage,
    pageTitle,
    sideBarItems: DrawerItems
  };

  return (
    <DrawerContext.Provider value={defaultContext}>
      {children}
    </DrawerContext.Provider>
  );
};