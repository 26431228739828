import axios from 'axios';
import {readStorage} from "../../client/utils/helpers";

type Platform = 'reddit' | 'twitter' | 'instagram' | 'facebook' | 'postNews' | 'threads';
type IssueType = 'pending' | 'failure' | 'resolved';

interface GetAccountProps {
  platform: Platform;
  accid?: string;
}

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:8080';
}

export const getIssues = (args: GetAccountProps) => {
  const {platform, accid} = args;
  return axios.get(`/issues?platform=${platform}${accid ? `&accid=${accid}` : ''}`, {auth: readStorage('user', true)});
};

interface ResolveIssueProps {
  id: string;
  type: IssueType;
  platform: Platform;
}

export const resolveIssue = (args: ResolveIssueProps) => {
  return axios.post('/issue/resolve', args, {auth: readStorage('user', true)})
};

export const processEmailVerification = (args: { id: string, challengeUrl: string, securityCode?: string }) => {
  return axios.post('/instagram/verification', args, {auth: readStorage('user', true)})
};

