import * as React from 'react';
import {createStyles, Theme} from "@material-ui/core/styles";
import {makeStyles, Paper} from "@material-ui/core";
import {CopyableText} from "../../../components";
import {Checkbox} from "../../../components/checkbox/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: '20px',
        },
        mt2: {
          marginTop: '2px',
        },
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            minWidth: '500px',
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        control: {
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        img: {
            width: '64px',
            marginLeft: '10px',
            borderRadius: '5px',
            boxShadow: '',
            transition: 'all .2s ease-in-out',
            '&:hover': {
                transform: 'scale(1.5)'
            }
        },
        textsmall: {
            maxWidth: '150px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
    }),
);

interface IProps {
    onTogglePosted(id?: any): void;

    deal: any;
}


export const Card: React.FC<IProps> = (props) => {
    const {onTogglePosted, deal = {}} = props;
    const {details = {}, postDetail = {}} = deal || {}
    const {title = '', body = '', imageUrl = '', googleLink = '', branchLink = ''} = details
    const {caption = '', hashtags = ''} = postDetail

    const classes = useStyles();

    function onToggle(state: boolean) {
        if (onTogglePosted) onTogglePosted(deal.deal_id)
    }


    return (
        <Paper className={classes.paper} style={{background: !deal.status ? '#259525' : '#FFF'}}>
            <div className={classes.root}>
                <div className={classes.item}>
                    <CopyableText text={deal.adpName}/>
                    <div className={classes.control}>
                        <a className={classes.control}
                           href={imageUrl}
                           target={'_blank'} hidden download>
                            <p>Download Image</p>
                            <img
                                className={classes.img}
                                src={imageUrl}
                                alt="Primary Image Url"/>
                        </a>
                    </div>
                    <div className='mt2'>
                        <CopyableText text={'Copy Post Caption'} value={caption}></CopyableText> <br/>
                        <CopyableText text={'Copy Post Title'} value={title}/> <br/>
                        <CopyableText text={'Copy Post Description'} value={body}/> <br/>
                        <CopyableText text={'Copy Post Flight Image URL'} value={postDetail.imageUrl}/> <br/>
                        <CopyableText text={'Copy Post Google Link'} value={googleLink}/> <br/>
                        <CopyableText text={'Copy Post HashTags'} value={hashtags}/> <br/>
                        <CopyableText text={'Copy Branch Link'} value={branchLink}/> <br/>
                    </div>
                </div>
                <div className={classes.control}>
                    <Checkbox onChange={onToggle} checked={true} disabled={!deal.status}/>
                </div>
            </div>
        </Paper>
    )
};
