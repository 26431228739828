import * as React from 'react';
import {AlertDialog, IndeterminentProgress, ProgressButton} from "../../../components";
import {Button, createStyles, FormGroup, makeStyles, TextField, Theme} from "@material-ui/core";
import {processEmailVerification} from "../../../../services/issues";
import {ChangeEvent} from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%',
    },
    flex: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: '10px',
    },
    error: {
      color: 'red',
    },
  }),
);


interface IProps {
  id: string;
  challengeUrl: string;

  // securityCode
  onClose(): void;
}

const _EmailVerificationModel: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const {id, onClose, challengeUrl} = props;
  const [emailsent, setEmailSent] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [apiError, setApiError] = React.useState<string>('');
  const [securityCode, setSecurityCode] = React.useState<string>('');

  function sendVerificationEmail() {
    setLoading(true);
    processEmailVerification({id, challengeUrl})
      .then(({data}) => {
        const {error, success} = data;
        if (error) {
          setApiError(error);
        } else {
          window && window.alert(success);
          setEmailSent(true);
        }
      })
      .catch((error) => {
        setApiError(error.message)
      })
      .finally(() => setLoading(false))
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setSecurityCode(e.currentTarget.value);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!securityCode) return;
    setLoading(true);
    processEmailVerification({id, challengeUrl, securityCode})
      .then(({data}) => {
        const {success, error} = data;
        if (error) {
          setApiError(error);
        } else {
          window && window.alert(success);
          setTimeout(() => {
            onClose();
          }, 3000)
        }
      })
      .catch((error) => {
        setApiError(error);
      })
      .finally(() => setLoading(false));
  }

  return (
    <AlertDialog
      isCancelable={emailsent || loading}
      onCancel={onClose}
      noButton={'Cancel'}
      heading={'Manual Email Verification'}
      message={!emailsent ? 'Click verify to start verification process' : 'Please enter Security code to verify account'}>
      {!emailsent && <ProgressButton loading={loading} disabled={loading} type={'button'} title={'Verify'}
									 onClick={sendVerificationEmail}/>}
      {!!emailsent && <div>
		  <form onSubmit={handleSubmit}>
            {!!loading && <IndeterminentProgress/>}
			  <FormGroup>
				  <TextField
					  autoComplete={'off'}
					  label="Security code"
					  className={classes.textField}
					  helperText="Enter security code"
					  margin="normal"
					  type="text"
					  name="securityCcode"
					  value={securityCode}
					  onChange={handleChange}
					  autoFocus={true}
					  disabled={loading}
					  required
				  />
				  <div className={classes.flex}>
					  <Button type="submit" color={'secondary'}>Submit</Button>
				  </div>
			  </FormGroup>
		  </form>
	  </div>}
      {!!apiError && <div className={classes.error}><p>{apiError}</p></div>}
    </AlertDialog>
  );
};

export const EmailVerificationModel = React.memo(_EmailVerificationModel);
