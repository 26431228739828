import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from "@material-ui/core/styles";
import { AppDrawer, Main, Navbar } from '../../components';
import { DrawerContext } from '../../context/ui-context'
import { IContextProps } from '../../context/ui-context/DrawerContext'
import { ToastContainer } from 'react-toastify'

const useStyles: any = makeStyles(theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(1),
  },
}));

const AuthenticatedLayout = () => {
  const classes = useStyles();
  const { isDrawerOpen, toggleDrawer, pageTitle } = React.useContext<IContextProps>(DrawerContext);


  return (
    <div className={classes.root}>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable={true}
        pauseOnHover={true} />
      <CssBaseline />
      <Navbar isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} pageTitle={pageTitle} />
      <AppDrawer open={isDrawerOpen} onClose={toggleDrawer} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.container}>
          <Main />
        </div>
      </main>
    </div>
  );
};

export default AuthenticatedLayout;