import * as React from 'react';
import { IndeterminentProgress, ProgressButton, SnackBar, TagsInput } from "../../components";
import { makeStyles, Paper, TextField } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { toast } from "react-toastify"

import { editProfileURL } from '../../../services/some';
import { ChipData } from "../../components/tags-input/TagsInput";
import { editAccount } from '../../../services/account';
import { IContextProps, DrawerContext } from '../../context/ui-context/DrawerContext'
import history from "../../utils/history";
import { RouteComponentProps } from "react-router";
import { IContextProps as IAccContext, AccountsContext } from "../../context/account/AccountContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexFlow: 'column wrap',
      padding: theme.spacing(0.5),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40%',
    },
    heading: {
      marginLeft: theme.spacing(1),
    },
    control: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '20px'
    }
  }),
);


interface IProps extends RouteComponentProps<{ id: string }> {
}

export const EditTwitter = (props: IProps) => {
  const { match: { params } } = props;
  if (!params.id) {
    history.push('/');
  }
  const { setActivePage } = React.useContext<IContextProps>(DrawerContext);
  setActivePage('twitter-edit', 'Edit Twitter account');

  const { fetchSingleAccount, singleAccount, loading: loadingSingle } = React.useContext<IAccContext>(AccountsContext);
  const formRef = React.useRef<any>();
  const defaultState = {
    url: "",
    username: "",
    name: "",
    password: "",
    proxy: "",
    phoneNumber: "",
    email: "",
    emailPassword: "",
    twitterAccessToken: "",
    twitterAccessTokenSecret: "",
    uniqueName: "",
    trelloCardId: "",
  };

  const [formState, setFormState] = React.useState<any>(defaultState);
  const [chips, setChips] = React.useState<{ tags: ChipData[] }>({ tags: [] });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [hashTags] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const classes = useStyles();

  React.useEffect(() => {
    if (singleAccount) {
      const {
        url,
        username,
        password,
        proxy,
        phoneNumber,
        email,
        name,
        emailPassword,
        twitterAccessToken,
        twitterAccessTokenSecret,
        iatas,
        uniqueName,
        hashTags = "",
        branchLink = "",
        trelloCardId = ''
      }: any = singleAccount;
      const tags: ChipData[] = [];
      if (iatas) {
        const _iatas: any[] = iatas;
        _iatas.forEach((item, index) => {
          tags.push({ key: index, label: item })
        });
      }
      setChips({ tags });
      setFormState({
        id: singleAccount.id,
        url: url ? url : "",
        username,
        password,
        proxy,
        phoneNumber,
        name,
        email,
        emailPassword,
        twitterAccessToken,
        twitterAccessTokenSecret,
        uniqueName,
        hashTags,
        branchLink,
        trelloCardId
      });
    }
  }, [singleAccount]);

  React.useEffect(() => {
    fetchSingleAccount('twitter', params.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTags = (tags: ChipData[]) => {
    setChips({ tags });
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    setFormState({ ...formState, [e.currentTarget.name]: e.currentTarget.value });
  }

  const editUrlOnSomeTracker = () => {
    const oldUrl: string = singleAccount.url;
    const newUrl: string = formState.url;
    editProfileURL(oldUrl, newUrl)
      .then(({ status }: { status: any }) => {
        if (status === 200) {
          toast.success('Updated twitter profile url on SoMe-tracker')
        }
      })
      .catch((error : any) => {
        toast.error('Could not update twitter profile url on SoMe-tracker')
      })
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    editUrlOnSomeTracker();
    const tags: string[] = [];
    chips.tags.map(tag => tags.push(tag.label));
    const args: any = {
      platform: 'twitter',
      iatas: tags,
      url: formState.url ? formState.url : '',
      ...formState
    };

    editAccount(args)
      .then(({ status }) => {
        if (status) {
          setMessage('Twitter account updated Successfully');
          setShowMessage(true);
        }
      })
      .catch((error) => {
        setMessage('Something went wrong');
        setShowMessage(true);
      })
      .finally(() => setLoading(false))
  }

  return (
    <Paper className={classes.root}>
      <h3 className={classes.heading}>Edit twitter account</h3>
      {!!(loading || loadingSingle) && <IndeterminentProgress />}
      <form onSubmit={handleSubmit} ref={formRef} noValidate={true}>
        <TagsInput
          required={!(chips && chips.tags && chips.tags.length)}
          onChange={onChangeTags}
          tags={chips.tags}
        />
        <TextField
          autoComplete={"off"}
          label="URL"
          className={classes.textField}
          helperText="Enter url"
          margin="normal"
          name="url"
          type="url"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.url}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Unique Name"
          className={classes.textField}
          helperText="Enter Unique Name"
          margin="normal"
          name="uniqueName"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.uniqueName}
          required
        />
        <TextField
          autoComplete={"off"}
          label="User name"
          className={classes.textField}
          helperText="Enter username"
          margin="normal"
          name="username"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.username}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Name"
          className={classes.textField}
          helperText="Enter Name"
          margin="normal"
          name="name"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.name}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Password"
          className={classes.textField}
          helperText="Enter password"
          margin="normal"
          type="text"
          name="password"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.password}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Proxy URL"
          className={classes.textField}
          helperText="Enter proxy url"
          margin="normal"
          name="proxy"
          type="text"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.proxy}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Phone #"
          className={classes.textField}
          helperText="Enter Phone #"
          margin="normal"
          name="phoneNumber"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.phoneNumber}
        />
        <TextField
          autoComplete={"off"}
          label="Email"
          className={classes.textField}
          helperText="Enter email"
          margin="normal"
          name="email"
          type="email"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.email}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Email password"
          className={classes.textField}
          helperText="Enter email password"
          margin="normal"
          type="password"
          name="emailPassword"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.emailPassword}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Access token"
          className={classes.textField}
          helperText="Enter access token"
          margin="normal"
          name="twitterAccessToken"
          type="text"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.twitterAccessToken}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Access token secret"
          className={classes.textField}
          helperText="Enter token secret"
          margin="normal"
          name="twitterAccessTokenSecret"
          type="text"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.twitterAccessTokenSecret}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Hash Tags"
          className={classes.textField}
          margin="normal"
          placeholder="Enter hash tags by comma separated"
          name="hashTags"
          type="text"
          value={formState.hashTags}
          onChange={handleChange}
          disabled={loading}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Branch Link"
          className={classes.textField}
          margin="normal"
          placeholder="Enter Branch Link"
          name="branchLink"
          type="text"
          value={formState.branchLink}
          onChange={handleChange}
          disabled={loading}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Trello Card Id"
          className={classes.textField}
          margin="normal"
          placeholder="Enter Trello Card Id"
          name="trelloCardId"
          type="text"
          value={formState.trelloCardId}
          onChange={handleChange}
          disabled={loading}
          required
        />
        <div className={classes.control}>
          <ProgressButton title={"Save"} type={"submit"} loading={loading} />
        </div>
      </form>
      {!!showMessage && (
        <SnackBar message={message} onClose={() => setShowMessage(false)} />
      )}
    </Paper>
  );
};
