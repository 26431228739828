import React from 'react'
import CloseIcon from '@material-ui/icons/Close';

import {
  createStyles,
  Theme,
  makeStyles,
  Chip,
  Link,
  LinearProgress,
  Button, TextField, Dialog, AppBar, IconButton, Toolbar, Typography, Slide, Badge, Grid
} from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'

import {
  AccountsContext,
  IContextProps
} from '../../context/account/AccountContext'
import {
  AlertDialog,
  IndeterminentProgress, ISwitch,
  PaginatedTabel
} from '../../components'
import {
  DrawerContext,
  IContextProps as DrawerContextProps
} from '../../context/ui-context/DrawerContext'
import { dateFormat, uniqueId } from '../../utils/helpers'
import { SlideProps } from "@material-ui/core/Slide";
import { CreatePostNews } from "./CreatePostNews";
import { EditPostNews } from "./EditPostNews";

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    table: {
      minWidth: 650
    },
    chip: {
      margin: theme.spacing(1)
    },
    pointer: {
      cursor: 'pointer'
    },
    formControl: {
      minWidth: 300
    },
    flux: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    cleanbtn: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      marginBottom: '15px'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40%',
    },
    head: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    container: {
      padding: 20,
    },
    rowVerticalPadding: {
      paddingTop: 20,
      paddingBottom: 20,
    }
  })
)

interface CleaningAccounts {
  progress: string[]
  finished: string[]
}

export const PostNewsListing = () => {
  const classes = useStyles()
  const { setActivePage } = React.useContext<DrawerContextProps>(DrawerContext)

  setActivePage('postNews-view', 'PostNews')

  const {
    loading,
    fetchAccounts,
    sortFilter,
    setSortFilter,
    postNewsAccounts,
    removeAccount,
    removing,
    onToggleCheck,
    enableDisableAllAcc,
    setSearch,
    search,
    updateAccount,
  } = React.useContext<IContextProps>(AccountsContext)
  const [deleteId, setDeleteId] = React.useState<string | number | null>('')
  const [addPopup, setAddPopup] = React.useState<boolean>(false)
  // const [healthCheckShow, setHealthCheckShow] = React.useState<boolean>(false)
  // const [trackbilityCheckShow, setTrackabilityCheckShow] = React.useState<boolean>(false)
  // const [healthCheckResponse, setHealthCheckResponse] = React.useState<any>(false)
  // const [trackablityCheckResponse, setTrackablityCheckResponse] = React.useState<any>(false)
  const [editAccount, setEditAccount] = React.useState<PostNewsAccounts | null>(null)

  const iAccounts = postNewsAccounts()
  React.useEffect(() => {
    fetchAccounts('postNews')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onDelete() {
    const id = deleteId
    setDeleteId(null)
    removeAccount('postNews', id)
  }

  // function accountsHealthCheck() {
  //   setHealthCheckShow(true);
  //   getAccountsHealthReport('postNews')
  //     .then(({ data }) => {
  //       console.log('data ayo', data);
  //       setHealthCheckResponse({
  //         success: true,
  //         data,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setHealthCheckResponse({
  //         succes: false,
  //         error: error.toString()
  //       });
  //     })
  //     // .finally(() => setHealthCheckResponse(false));
  // }
  

  function onUpdateSortFilter(filter?: {
    key: string
    direction: 'asc' | 'desc'
  }) {
    setSortFilter(filter)
  }

  function onDeleteCancel() {
    setDeleteId(null)
  }

  function onToggleChk(data: { id: any, checked: boolean }) {
    const { checked, id } = data;
    onToggleCheck({ id, checked, platform: 'postNews' });
  }

  function onEnable(e: any) {
    e.preventDefault();
    enableDisableAllAcc({ enable: true, platform: 'postNews' })
  }

  function onDisable(e: any) {
    e.preventDefault();
    enableDisableAllAcc({ enable: false, platform: 'postNews' })
  }

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.currentTarget.value)
  }

  function onCloseEdit(account?: PostNewsAccounts) {
    if (account) {
      updateAccount({ account, platform: "postNews" })
    }
    setEditAccount(null);
  }

  const data: any[][] = []
  if (iAccounts && iAccounts.length) {
    iAccounts.map((row: PostNewsAccounts, index) => {
      const singleRow: any[] = []
      const IATAS: any[] = []
      row.iatas.map((item: any) => {
        IATAS.push(
          <Chip key={uniqueId()} label={item} className={classes.chip} />
        )
        return null
      })
      singleRow.push(index + 1)
      singleRow.push(<div key={uniqueId()}>{IATAS}</div>)
      singleRow.push(<div key={uniqueId()}>{row.uniqueName}</div>)
      singleRow.push(<div key={uniqueId()}>{row.email}</div>)
      singleRow.push(
        row.trelloCardId ? <Link
          key={uniqueId()}
          href={`https://trello.com/c/${row.trelloCardId}`}
          target={"_blank"}
        >
          {row.trelloCardId}
        </Link>: null
      );
      singleRow.push(dateFormat(row.createdAt))
      singleRow.push(<ISwitch checked={!!row.isActive} id={row.id} onChange={onToggleChk} />)
      singleRow.push(
        <Edit
          key={uniqueId()}
          className={classes.pointer}
          color={'primary'}
          onClick={() => setEditAccount(row)}
        />
      )
      singleRow.push(
        <Delete
          key={uniqueId()}
          className={classes.pointer}
          color={'secondary'}
          onClick={() => setDeleteId(row.id)}
        />
      )
      data.push(singleRow)
      return null
    })
  }

  return (
    <div className={classes.root}>
      {loading && <LinearProgress key={uniqueId()} color="secondary" />}
      {removing && <IndeterminentProgress key={uniqueId()} />}

      <div className={classes.head}>
        <TextField
          autoComplete={"off"}
          label="ADP Name"
          className={classes.textField}
          helperText="Search by adp name"
          margin="normal"
          name="search"
          type="text"
          value={search}
          onChange={onSearchChange}
          autoFocus={true}
          disabled={loading}
        />
        <Button
          type={"button"}
          onClick={onEnable}
          disabled={loading || !(iAccounts && iAccounts.length)}
          color={"primary"}
        >
          Enable All
        </Button>
        <Button
          type={"button"}
          onClick={onDisable}
          disabled={loading || !(iAccounts && iAccounts.length)}
          color={"secondary"}
        >
          Disable All
        </Button>
        <Button
          type={"button"}
          onClick={() => setAddPopup(true)}
          disabled={loading}
          color={"secondary"}
        >
          Add New
        </Button>
        {/* <Button
          type={"button"}
          onClick={() => accountsHealthCheck()}
          disabled={loading}
          color={"secondary"}
        >
          Autoposter Check
        </Button>
        <Button
          type={"button"}
          onClick={() => accountsTrackablityCheck()}
          disabled={loading}
          color={"secondary"}
        >
          SomeTrackablity Checker
        </Button> */}
      </div>
      <PaginatedTabel
        data={data}
        onUpdateSortFilter={onUpdateSortFilter}
        currentFilter={sortFilter}
        sortableHeader={[
          { label: "#", key: "", sortable: false },
          { label: "IATAs", key: "iatas", sortable: true },
          { label: "Unique Name", key: "uniqueName", sortable: true },
          { label: "email", key: "email", sortable: true },
          { label: "Trello Card", key: "trelloCardId", sortable: true },
          { label: "Created at", key: "createdAt", sortable: true },
          { label: "Status", key: "isActive", sortable: false },
          { label: "Edit", key: "", sortable: false },
          { label: "Delete", key: "", sortable: false },
        ]}
      />
      {!!deleteId && (
        <AlertDialog
          heading={"Are you sure?"}
          message="You are deleting this account, you might not be able to recover it again?"
          onCancel={onDeleteCancel}
          onAccept={onDelete}
        />
      )}
      {!!addPopup && (
        <Dialog
          fullScreen
          open={true}
          onClose={() => setAddPopup(false)}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setAddPopup(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Add PostNews Account
              </Typography>
            </Toolbar>
          </AppBar>
          <CreatePostNews />
        </Dialog>
      )}
     
     
      {!!editAccount && (
        <Dialog
          fullScreen
          open={true}
          onClose={() => onCloseEdit()}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => onCloseEdit()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Edit PostNews Account
              </Typography>
            </Toolbar>
          </AppBar>
          <EditPostNews account={editAccount} onClose={onCloseEdit} />
        </Dialog>
      )}
    </div>
  );
}
