import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from "@material-ui/core/Badge";
import MoreVert from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import {AppContext} from "../../context/AppContext";

export const NavBarMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {logout} = React.useContext(AppContext);

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
    logout();
  }

  return (
    <div>

      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={0} color="secondary">
          <MoreVert/>
        </Badge>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </div>
  );
}