import * as React from 'react';
import {IndeterminentProgress, ISwitch, ProgressButton, SnackBar, TagsInput} from "../../components";
import {FormControl, InputLabel, makeStyles, MenuItem, Paper, Select, Switch, TextField, Typography} from "@material-ui/core";
import {createStyles, Theme} from "@material-ui/core/styles";
import {ChipData} from "../../components/tags-input/TagsInput";
import {editAccount} from '../../../services/account';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      marginLeft: "300px;",
    },
    root: {
      display: "flex",
      justifyContent: "left",
      flexFlow: "column wrap",
      padding: theme.spacing(0.5),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "40%",
    },
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    select: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
    },
    heading: {
      marginLeft: theme.spacing(1),
    },
    control: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: "20px",
    },
    error: {
      color: "red",
      padding: "5px",
    },
  })
);

interface IProps {
  account: InstagramAccounts;

  onClose(account?: InstagramAccounts): void;
}

export const EditInstagram = (props: IProps) => {
  const [chips, setChips] = React.useState<{ tags: ChipData[] }>({tags: []});
  const formRef = React.useRef<any>();
  const [formState, setFormState] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [hashTags] = React.useState<string>('');
  const [branchLink] = React.useState<string>('');
  const [nameError, setnameError] = React.useState<string>('');
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [accountStatus, setAccountStatus] = React.useState<boolean>(false);


  const classes = useStyles();
  const {onClose, account} = props;
  console.log('account is', account);
  const onChangeTags = (tags: ChipData[]) => {
    setChips({tags});
  };

  function setDefaultState() {
    setFormState({
      ...formState,
      adpName: account.adpName,
      username: account.username,
      trackable: account.trackable,
      hashTags: account.hashTags || '',
      accessToken: account.accessToken || '',
      pageAccessToken: account.pageAccessToken || '',
      pageId: account.pageId || '',
      accountType: account.accountType || '',
      trelloCardId: account.trelloCardId || '',
      branchLink: account.branchLink || '',
    })
    console.log("default are", {
      accessToken: account.accessToken || "",
      accountType: account.accountType || "",
      trelloCardId: account.trelloCardId || "",
      branchLink: account.branchLink || "",
    });
    setChips({
      tags: account.iatas.map((item, index) => {
        return {key: index, label: item};
      })
    })
    setAccountStatus(!!account.isActive)
  }

  React.useEffect(setDefaultState, [account]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    e.preventDefault();
    setFormState({...formState, [e.currentTarget.name]: e.currentTarget.value});
    if (error) setError('');
    validate();
  }

  function handleSelect(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    console.log('event is', event);
        setFormState({
          ...formState,
          [event.target.name as string]: event.target.value,
        });
  }

  function validate() {
    const regexp = /\s/g
    if (regexp.test(formState.adpName) || regexp.test(formState.username)) {
      setnameError("Whitespace is not allowed");
    } else {
      setnameError("");
    }
  }

  function onChangeAccountSts() {
    setAccountStatus(!accountStatus)
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    const tags: string[] = [];
    chips.tags.map(tag => tags.push(tag.label));
    const args: any = {
      id: account.id,
      platform: 'instagram',
      isActive: accountStatus,
      iatas: tags,
      ...formState
    };

    editAccount(args)
      .then(({data}) => {
        if (data.error) {
          setError(data.error)
        } else if (data) {
          onClose(data);
        }
      })
      .catch((error) => {
        const {response: {data}} = error;
        if (data) {
          setError(data.error)
        } else {
          setMessage('Something went wrong');
          setShowMessage(true);
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <Paper className={classes.root}>
      <h3 className={classes.heading}>Edit Instagram account</h3>
      {!!loading && <IndeterminentProgress />}
      <form onSubmit={handleSubmit} ref={formRef} noValidate={true}>
        <TagsInput onChange={onChangeTags} tags={chips.tags} />
        <TextField
          autoComplete={"off"}
          label="ADP Name"
          className={classes.textField}
          helperText={
            !!nameError
              ? "Whitespaces are not allowed"
              : "Enter adp unique name"
          }
          margin="normal"
          name="adpName"
          type="text"
          value={formState.adpName}
          error={!!nameError}
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Username"
          className={classes.textField}
          helperText={
            !!nameError ? "Whitespaces are not allowed" : "Enter username"
          }
          margin="normal"
          name="username"
          type="text"
          value={formState.username}
          error={!!nameError}
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        />

        <TextField
          autoComplete={"off"}
          label="Hash Tags"
          className={classes.textField}
          margin="normal"
          placeholder="Enter hash tags by comma separated"
          name="hashTags"
          type="text"
          value={formState.hashTags}
          onChange={handleChange}
          disabled={loading}
          required
        />

        <TextField
          autoComplete={"off"}
          label="Branch Link"
          className={classes.textField}
          margin="normal"
          placeholder="Enter Branch Link"
          name="branchLink"
          type="text"
          value={formState.branchLink}
          onChange={handleChange}
          disabled={loading}
          required
        />

        <TextField
          autoComplete={"off"}
          label="Trello Card Id"
          className={classes.textField}
          margin="normal"
          placeholder="Trello Card Id"
          name="trelloCardId"
          type="text"
          value={formState.trelloCardId}
          onChange={handleChange}
          disabled={loading}
          required
        />
        <TextField
          autoComplete={"off"}
          label="AccessToken"
          value={formState.accessToken}
          className={classes.textField}
          // helperText={
          //   !!nameError
          //     ? "Whitespaces are not allowed"
          //     : "Enter adp unique name"
          // }
          margin="normal"
          name="accessToken"
          type="text"
          aria-readonly
          // error={!!nameError}
          onChange={handleChange}
          autoFocus={true}
          disabled={true}
          // required
        />
        <Typography
          // className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Some Trackable {!!formState.trackable ? 'YES' : 'NO'}
        </Typography>
        <TextField
          autoComplete={"off"}
          label="Page Id"
          value={formState.pageId}
          className={classes.textField}
          // helperText={
          //   !!nameError
          //     ? "Whitespaces are not allowed"
          //     : "Enter adp unique name"
          // }
          margin="normal"
          name="pageId"
          type="text"
          // aria-readonly
          // error={!!nameError}
          onChange={handleChange}
          autoFocus={true}
          // disabled={true}
          // required
        />
        <TextField
          autoComplete={"off"}
          label="Page Access token"
          value={formState.pageAccessToken}
          className={classes.textField}
          // helperText={
          //   !!nameError
          //     ? "Whitespaces are not allowed"
          //     : "Enter adp unique name"
          // }
          margin="normal"
          name="pageAccessToken"
          type="text"
          // aria-readonly
          // error={!!nameError}
          onChange={handleChange}
          autoFocus={true}
          // disabled={true}
          // required
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="simple-select">IG Account Typee</InputLabel>
          <Select
            value={formState.accountType || ""}
            onChange={handleSelect}
            className={classes.select}
            // defaultValue={''}
            placeholder="IG Account type"
            name="accountType"
            // margin="normal"
            // error={error}
            // input={
            //   <OutlinedInput
            //     labelWidth={200}
            //     name="platform"
            //     id="outlined-age-simple"
            //   />
            // }
          >
            <MenuItem value="">
              <em>None</em>
              {/* None */}
            </MenuItem>
            <MenuItem value={"BUSINESS"}>BUSINESS</MenuItem>
            <MenuItem value={"MEDIA_CREATOR"}>MEDIA_CREATOR</MenuItem>
            <MenuItem value={"PERSONAL"}>PERSONAL</MenuItem>
          </Select>
        </FormControl>

        <div className={classes.checkbox}>
          <ISwitch
            checked={accountStatus}
            id={1}
            onChange={onChangeAccountSts}
          />
        </div>
        {!!error && <p className={classes.error}>{error}</p>}
        <div className={classes.control}>
          <ProgressButton
            title={"Save"}
            type={"submit"}
            loading={loading}
            disabled={loading || !!error || !!nameError}
          />
        </div>
      </form>
      {!!showMessage && (
        <SnackBar message={message} onClose={() => setShowMessage(false)} />
      )}
    </Paper>
  );
};
