import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import ReactJson from 'react-json-view';
import {object} from "prop-types";


interface IProps {
  heading: string;
  message?: string;
  yesButton?: string;
  noButton?: string;
  id?: any;
  isJsonViewer?: boolean;
  json?: any;
  isCancelable?: boolean;
  onCancel?(): void;

  onAccept?(id?: any): void;
}

export const AlertDialog: React.FC<IProps> = (props) => {
  const {message, noButton, id, heading, onAccept, onCancel, yesButton, isJsonViewer, json, isCancelable} = props;

  function handleClose() {
    if (onCancel) onCancel();
  }

  function handleAccept() {
    if (onAccept) {
      onAccept(id);
    } else if (onCancel) {
      onCancel();
    }
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{heading}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message && message}
          {(isJsonViewer && json) &&
          <ReactJson
            iconStyle={'triangle'}
            theme={'monokai'}
            displayObjectSize={false}
            displayDataTypes={false}
            collapseStringsAfterLength={1000}
            src={(json instanceof object ? JSON.stringify(json) : json)}
          />}
        </DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button disabled={isCancelable ? true : false} onClick={handleClose} color="primary">
          {noButton ? noButton : 'No'}
        </Button>
        {onAccept && <Button onClick={handleAccept} color="primary" autoFocus>
          {yesButton ? yesButton : 'Yes'}
		</Button>}
      </DialogActions>
    </Dialog>
  );
}
