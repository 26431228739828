import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import copy from 'copy-to-clipboard';

interface IProps {
  text: string;
  value?: string;
  bold?: boolean;
}

export const CopyableText: React.FC<IProps> = (props) => {
  const {text, value, bold} = props;
  const [open, setOpen] = React.useState(false);

  function handleTooltipClose() {
    setOpen(false);
  }

  function handleTooltipOpen() {
    setOpen(true);
    copy(value ? value : '');
    setTimeout(handleTooltipClose, 1000);
  }


  return (
    <div>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied!"
          >
            <p
              style={{
                cursor: 'copy', margin: '0',
                fontWeight: bold ? 'bold' : 'normal',
                marginRight: '10px',
              }}
              onClick={handleTooltipOpen}
            >
              {text}
            </p>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
}