import * as React from 'react';
import {AlertDialog, PaginatedTabel} from "../../../components";
import {dateFormat, issueReasonsMapper, issueTypeMapper} from "../../../utils/helpers";
import {createStyles, Link, makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    pointer: {
      cursor: 'pointer',
      color: '#09701c'
    }
  }),
);

interface IProps {
  issues: IssueItem[];
}

export const FailureIssues = (props: IProps) => {
  const classes = useStyles();
  const [jsonData, setJsonData] = React.useState<object | null>(null);

  function onResolveCancel() {
    setJsonData(null);
  }

  const {issues} = props;
  const data: any[][] = [];
  if (issues && issues.length) {
    issues.map((row: IssueItem, index) => {
      const singleRow: any[] = [];
      singleRow.push(index + 1);
      singleRow.push(row.id);
      singleRow.push(issueTypeMapper(row.type));
      singleRow.push(
        <Link
          className={classes.pointer}
          onClick={() => setJsonData(row.data)}
        >
          View data
        </Link>
      );
      singleRow.push(issueReasonsMapper(row.reason));
      singleRow.push(row.status);
      singleRow.push(dateFormat(row.updatedAt));


      data.push(singleRow);
      return null;
    });
  }
  return (
    <div className={classes.root}>
      <PaginatedTabel
        data={data}
        header={['#', 'Id', 'Type', 'Data', 'Reason', 'Status', 'Updated At']}
      />
      {!!jsonData &&
      <AlertDialog
        heading={'Data'}
        isJsonViewer={true}
        json={jsonData}
        onCancel={onResolveCancel}
        noButton={'Close'}
      />
      }
    </div>
  );
};
