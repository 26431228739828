import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme, Theme, createStyles} from '@material-ui/core/styles';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  TableSortLabel,
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {uniqueId} from "../../utils/helpers";

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const theme = useTheme();
  const {count, page, rowsPerPage, onChangePage} = props;

  function handleFirstPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table: {
      minWidth: 500,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    noItem: {
      color: 'red',
      textAlign: 'center',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }),
);

interface ISortable {
  mkey: string;
  label: string;
  sortable: boolean;
  selected: boolean;
  currentFilter?: { key: string, direction: 'asc' | 'desc' }

  onUpdateFilter?(filter?: { key: string, direction: 'asc' | 'desc' }): void;
}

const SortableHeader = (props: ISortable) => {
  const {mkey, label, onUpdateFilter, selected, currentFilter} = props;


  function hanleUpdateFilter() {
    if (onUpdateFilter) {
      if (selected && (currentFilter && currentFilter.direction === 'asc')) {
        onUpdateFilter({key: mkey, direction: 'desc'});
      } else {
        onUpdateFilter({key: mkey, direction: 'asc'});
      }
    }
  }

  if (!props.sortable) {
    return <p>{label}</p>;
  }
  return (
    <TableSortLabel
      active={selected}
      direction={currentFilter ? currentFilter.direction : 'asc'}
      onClick={hanleUpdateFilter}
    >
      {label}
    </TableSortLabel>
  );
};

interface IProps {
  header?: string[];
  sortableHeader?: { visible?: boolean, label: string, key: string, sortable: boolean }[];
  data: any[][];
  currentFilter?: { key: string, direction: 'asc' | 'desc' };

  onUpdateSortFilter?(filter?: { key: string, direction: 'asc' | 'desc' }): void;
}

export const PaginatedTabel = (props: IProps) => {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const {data, header, sortableHeader, currentFilter, onUpdateSortFilter} = props;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          {!!sortableHeader &&
          <TableHead>

            <TableRow key={`header-${uniqueId()}`}>
              {sortableHeader.map(item =>
                <TableCell key={uniqueId()}>
                  <SortableHeader
                    selected={!!(currentFilter && currentFilter.key === item.key)}
                    currentFilter={currentFilter}
                    onUpdateFilter={onUpdateSortFilter}
                    mkey={item.key}
                    label={item.label}
                    sortable={item.sortable}/>
                </TableCell>)}
            </TableRow>
          </TableHead>
          }

          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={`row-${uniqueId()}`}>
                {row.map((item, index) => {
                  if (index === 0) {
                    return <TableCell
                      key={`key-${uniqueId()}${Math.random()}`}
                      component="th"
                      scope="row">
                      {item}
                    </TableCell>;
                  } else {
                    return <TableCell
                      key={`key-${uniqueId()}${Math.random()}`}>
                      {item}
                    </TableCell>;
                  }
                })}
              </TableRow>
            ))}
            {!(data && data.length) &&
            <TableRow><TableCell className={classes.noItem} colSpan={header ? header.length : 0}>No item
              found</TableCell></TableRow>}
            {emptyRows > 0 && (
              <TableRow key={`random-${uniqueId()}`} style={{height: 48 * emptyRows}}>
                <TableCell colSpan={6}/>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>

            <TableRow key={uniqueId()}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 500, 1000]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {'aria-label': 'Rows per page'},
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
}