import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {IContextProps} from "../../context/ui-context/DrawerContext";
import {DrawerContext} from "../../context/ui-context";
import {CollapsableMenu} from "./CollapsableMenue";
import {uniqueId} from "../../utils/helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minWidth: 300,
      maxWidth: 300,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
  }),
);

interface IProps {
  currentRoute: string;
}

export const NavbarItems = (props: IProps) => {
  const classes = useStyles();
  const {sideBarItems, onRouteChange, activePage} = React.useContext<IContextProps>(DrawerContext);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {!!(sideBarItems && sideBarItems.length) &&
      sideBarItems.map((item: SideBarItem, index) => {
        return (
          <div key={uniqueId()}>
            {!(item.children && item.children.length) &&
            <ListItem
              key={uniqueId()}
              button
              onClick={() => onRouteChange(item.route)}
              selected={item.slug === activePage}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name}/>
            </ListItem>
            }

            {!!(item.children && item.children.length) &&
            <CollapsableMenu
              key={uniqueId()}
              item={item}
              onRouteChange={onRouteChange}
              activePage={activePage}
            />
            }
          </div>
        );
      })
      }
    </List>
  );
};