import * as React from 'react';
import {CheckCircle, RadioButtonUnchecked} from "@material-ui/icons";
import {Checkbox as ChkBox} from "@material-ui/core";

interface IProps {
  onChange(state: boolean): void;

  disabled?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
}

export const Checkbox: React.FC<IProps> = (props) => {
  const {onChange, disabled, defaultChecked} = props;

  function handleChange(e: any, state: boolean) {
    onChange(state);
  }

  return (
    <ChkBox
      disabled={!!disabled}
      icon={<RadioButtonUnchecked/>}
      checkedIcon={<CheckCircle/>}
      value="checkedH"
      onChange={handleChange}
      defaultChecked={!!defaultChecked}
    />
  );
};