import * as React from 'react';
import AuthenticatedLayout from "./pages/authenticated-layout/AuthenticatedLayout";
import { UnAuthenticatedLayout } from "./pages/unauth-layout/UnAuthenticatedLayout";
import { AppContext } from "./context/AppContext";
import 'react-toastify/dist/ReactToastify.css'

export const App = () => {
  const { isAuth } = React.useContext(AppContext);

  return isAuth ? <AuthenticatedLayout /> : <UnAuthenticatedLayout />;
};
