import React from 'react'
import {
  createStyles,
  Theme,
  makeStyles,
  Chip,
  LinearProgress, Button, TextField
} from '@material-ui/core'
import history from '../../utils/history'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import { toast } from "react-toastify"

import { removeProfileURL } from '../../../services/some';
import {
  AccountsContext,
  IContextProps
} from '../../context/account/AccountContext'
import {
  AlertDialog,
  IndeterminentProgress, ISwitch,
  PaginatedTabel
} from '../../components'
import {
  DrawerContext,
  IContextProps as DrawerContextProps
} from '../../context/ui-context/DrawerContext'
import { dateFormat, uniqueId } from '../../utils/helpers'
import { Link } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    table: {
      minWidth: 650
    },
    chip: {
      margin: theme.spacing(1)
    },
    pointer: {
      cursor: 'pointer'
    },
    formControl: {
      minWidth: 300
    },
    flux: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40%',
    },
    head: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    }
  })
)

export const ThreadsListing = () => {
  const classes = useStyles()
  const { setActivePage } = React.useContext<DrawerContextProps>(DrawerContext)
  setActivePage('threads-view', 'Threads')

  const {
    loading,
    sortFilter,
    setSortFilter,
    fetchAccounts,
    threadsAccounts,
    removeAccount,
    onToggleCheck,
    enableDisableAllAcc,
    removing,
    search,
    setSearch,
  } = React.useContext<IContextProps>(AccountsContext)
  const [deleteId, setDeleteId] = React.useState<string | number | null>('')

  const iAccounts = threadsAccounts();

  React.useEffect(() => {
    fetchAccounts('threads')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onUpdateSortFilter(filter?: {
    key: string
    direction: 'asc' | 'desc'
  }) {
    setSortFilter(filter)
  }

  function onDelete() {
    // removeUrlFromSomeTracker()
    const id = deleteId
    setDeleteId(null)
    removeAccount('threads', id)
  }

  function onDeleteCancel() {
    setDeleteId(null)
  }

  function onToggleChk(data: { id: any, checked: boolean }) {
    const { checked, id } = data;
    console.log('clicked');
    onToggleCheck({ id, checked, platform: 'threads' });
  }

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.currentTarget.value)
  }

  const data: any[][] = []
  if (iAccounts && iAccounts.length) {
    iAccounts.map((row: ThreadsAccounts, index: number) => {
      const singleRow: any[] = []
      const IATAS: any[] = []
      if (!row.iatas) row.iatas = []
      row.iatas.map((item: any) => {
        IATAS.push(
          <Chip key={uniqueId()} label={item} className={classes.chip} />
        )
        return null
      })
      singleRow.push(<ISwitch checked={!!row.isActive} id={row.id} onChange={onToggleChk} />)
      singleRow.push(index + 1)
      singleRow.push(<div key={uniqueId()}>{IATAS}</div>)
      // singleRow.push(row.name ? row.name : 'N/A')
      singleRow.push(row.username)
      singleRow.push(row.uniqueName)
      singleRow.push(row.userId)
      singleRow.push(
        row.trelloCardId ? <Link
          key={uniqueId()}
          href={`https://trello.com/c/${row.trelloCardId}`}
          target={"_blank"}
        >
          {row.trelloCardId}
        </Link> : null
      );
       singleRow.push(
         <Link key={uniqueId()} href={row.url} target={"_blank"}>
           {row.url}
         </Link>
       );
      singleRow.push(dateFormat(row.createdAt))
      singleRow.push(
        <Edit
          key={uniqueId()}
          className={classes.pointer}
          color={'primary'}
          onClick={() => history.push(`/dashboard/threads/${row.id}`)}
        />
      )
      singleRow.push(
        <Delete
          key={uniqueId()}
          className={classes.pointer}
          color={'secondary'}
          onClick={() => setDeleteId(row.id)}
        />
      )
      data.push(singleRow)
      return null
    })
  }

  return (
    <div className={classes.root}>
      {loading && <LinearProgress key={uniqueId()} color="secondary" />}
      {removing && <IndeterminentProgress key={uniqueId()} />}
      <div className={classes.head}>
        <TextField
          autoComplete={"off"}
          label="Email or IATA"
          className={classes.textField}
          helperText="Search by email or IATA"
          margin="normal"
          name="search"
          type="text"
          value={search}
          onChange={onSearchChange}
          autoFocus={true}
          disabled={loading}
        />
        <Button
          type={"button"}
          onClick={(e) =>
            enableDisableAllAcc({ enable: true, platform: "threads" })
          }
          color={"primary"}
        >
          Enable All
        </Button>
        <Button
          type={"button"}
          onClick={(e) =>
            enableDisableAllAcc({ enable: false, platform: "threads" })
          }
          color={"secondary"}
        >
          Disable All
        </Button>
      </div>
      <PaginatedTabel
        onUpdateSortFilter={onUpdateSortFilter}
        data={data}
        currentFilter={sortFilter}
        sortableHeader={[
          { label: "Status", key: "isActive", sortable: false },
          { label: "#", key: "", sortable: false },
          { label: "IATAs", key: "iatas", sortable: true },
          { label: "Username", key: "username", sortable: true },
          { label: "Unique Name", key: "uniqueName", sortable: true },
          { label: "UserId", key: "userId", sortable: true },
          { label: "Trello Card", key: "trelloCardId", sortable: true },
          { label: "Url", key: "url", sortable: true },
          { label: "Created at", key: "createdAt", sortable: true },
          { label: "Edit", key: "", sortable: false },
          { label: "Delete", key: "", sortable: false },
        ]}
      />
      {!!deleteId && (
        <AlertDialog
          heading={"Are you sure?"}
          message="You are deleting this account, you might not be able to recover it again?"
          onCancel={onDeleteCancel}
          onAccept={onDelete}
        />
      )}
    </div>
  );
}
