import React from 'react';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {DrawerContext, IContextProps as DrawerContextProps} from "../../context/ui-context/DrawerContext";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from "@material-ui/core";
import {donwloadCsv, downloadAccountsBackup} from '../../../services';
import fileDownload from 'js-file-download';
import {red} from "@material-ui/core/colors";
import {AccountsContext, IContextProps} from "../../context/account/AccountContext";
import history from '../../utils/history';
import {IndeterminentProgress} from "../../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    flux: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    button: {
      margin: theme.spacing(1),
    },
    error: {
      color: 'red',
    },
    card: {
      '&:hover': {
        boxShadow: '5px 5px 5px 5px #286088',
      },
      cursor: 'pointer',
      maxWidth: 320,
      display: 'flex',
      margin: '10px',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    icon: {
      width: '90px',
      height: '70px',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#f44336',
      fontSize: '2rem',
      color: '#FFF'
    },
    title: {
      width: '100%',
      height: '70px',
      display: 'flex',
      minWidth: '200px',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.5rem',
      background: '#fff'
    },
    stats: {
      display: 'flex',
      margin: '0 0 20px 20px',
      justifyContent: 'space-between',
    },
  }),
);

interface IProps {
}

const StatsCard: React.FC<{ title: string, value: any, color?: string }> = (props) => {
  const classes = useStyles();
  const {value, title, color} = props;
  return (
    <Card className={classes.card} raised={true} onClick={() => history.push(`/dashboard/${title.toLowerCase()}`)}>
      <div className={classes.icon} style={{background: color ? color : '#f44336'}}>{value}</div>
      <div className={classes.title}>{title}</div>
    </Card>
  );
};

export const Dashboard = (props: IProps) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    platform: '',
  });
  const [error, setError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorString, setErrorString] = React.useState<string>('');
  const {
    fetchAccounts,
    twitterAccounts,
    instagramAccounts,
    redditAccounts,
    facebookAccounts,
    postNewsAccounts,
  } = React.useContext<IContextProps>(AccountsContext);

  const redditAccc = redditAccounts();
  const instaAcc = instagramAccounts();
  const twitterAcc = twitterAccounts();
  const facebookAcc = facebookAccounts();
  const postNewsAccs = postNewsAccounts();
  React.useEffect(() => {
    fetchAccounts('twitter');
    fetchAccounts('instagram');
    fetchAccounts('reddit');
    fetchAccounts('facebook');
    fetchAccounts('postNews');
    // eslint-disable-next-line
  }, []);

  const inputLabel = React.useRef<HTMLLabelElement>(null);


  function handleChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name as string]: event.target.value,
    }));
  }

  function downloadBackup() {
    setLoading(true);
    downloadAccountsBackup()
      .then(({data}) => {
        if (data) fileDownload(JSON.stringify(data), `accounts-${new Date().toISOString()}.json`);
        if (!data) setErrorString('No data found or something went wrong!')
      })
      .catch(error => setErrorString(error.message))
      .finally(() => setLoading(false))
  }

  function onSubmit() {
    if (!values.platform) {
      setError(true);
    } else {
      setLoading(false)
      donwloadCsv({platform: values.platform})
        .then(({data}) => {
          const {error} = data;
          if (error) {
            setErrorString(error);
          } else {
            if (data) fileDownload(data, `${values.platform}-${new Date().toISOString()}.csv`);
          }
        })
        .catch(e => {
          setErrorString(e.message);
        })
        .finally(() => setLoading(false))
    }
  }

  const {setActivePage} = React.useContext<DrawerContextProps>(DrawerContext);
  setActivePage('dashboard', 'Dashboard');
  return (
    <div>
      {!!loading && <IndeterminentProgress/>}
      <Paper className={classes.root}>
        <div className={classes.stats}>
          <StatsCard title={'Instagram'} value={instaAcc ? instaAcc.length : 0}/>
          <StatsCard title={'Twitter'} value={twitterAcc ? twitterAcc.length : 0}/>
          <StatsCard title={'Reddit'} value={redditAccc ? redditAccc.length : 0}/>
          <StatsCard title={'Facebook'} value={facebookAcc ? facebookAcc.length : 0}/>
          <StatsCard title={'PostNews'} value={postNewsAccs ? postNewsAccs.length : 0}/>
        </div>

        <Typography variant="h5" component="h3">
          Select Platform to download csv
        </Typography>
        <div className={classes.flux}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
              Select Platform
            </InputLabel>
            <Select
              value={values.platform}
              onChange={handleChange}
              error={error}
              input={<OutlinedInput labelWidth={200} name="platform" id="outlined-age-simple"/>}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'instagram'}>Instagram</MenuItem>
              <MenuItem value={'reddit'}>Reddit</MenuItem>
              <MenuItem value={'twitter'}>Twitter</MenuItem>
              <MenuItem value={'facebook'}>Facebook</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <Button disabled={loading} variant="contained" onClick={onSubmit} color="secondary"
                    className={classes.button}>
              Download
            </Button>
          </FormControl>
        </div>
        <FormControl>
          <Button disabled={loading} variant="contained" onClick={downloadBackup} color="secondary"
                  className={classes.button}>
            Download Accounts Backup
          </Button>
        </FormControl>
        {errorString && <p className={classes.error}>{errorString}</p>}
      </Paper>
    </div>
  );
};


