import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/browser'

import { Router } from 'react-router-dom'
import 'typeface-roboto'
import history from './client/utils/history'
import { App } from './client/App'
import { AppContextProvider } from './client/context/AppContext'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { blueGrey } from '@material-ui/core/colors'

Sentry.init({
  dsn: 'https://de551cca91814f33b11a5edcedead397@sentry.io/1546216'
})

const theme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#ffffff'
    }
  }
})

const WrapperApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <Router history={history}>
          <App />
        </Router>
      </AppContextProvider>
    </ThemeProvider>
  )
}

ReactDOM.render(<WrapperApp />, document.getElementById('root'))
