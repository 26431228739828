import axios from "axios";
import { readStorage } from "../../client/utils/helpers";

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:8080';
}


export const getAllTemplates = () => {
  return axios.get('/apTemplate/getAll', {
    // TODO fetch from env var later
    auth: {
      username: "admin",
      password: "superadminsecret"
    }
  }).catch(e => {
    return {
      error: e.toString()
    }
  })
}

export const getTemplateByPlatform = (plaftorm: any) => {
  return axios.get(`/apTemplate/getByPlatform/${plaftorm}`, {
    auth: {
      username: "admin",
      password: "superadminsecret"
    }
  }).catch(e => {
    return {
      error: e.toString()
    }
  })
}
export const createOrUpdateByPlatform = (plaftorm: any, payload: any) => {
  return axios.post(`/apTemplate/createOrUpdateByPlatform/${plaftorm}`, payload, {
    auth: {
      username: "admin",
      password: "superadminsecret"
    }
  }).catch(e => {
    return {
      error: e.toString()
    }
  });
}


