import * as React from 'react';
import DoneOutline from '@material-ui/icons/DoneOutline';
import {AlertDialog, IndeterminentProgress, PaginatedTabel} from "../../../components";
import {dateFormat, issueReasonsMapper, issueTypeMapper, uniqueId} from "../../../utils/helpers";
import {createStyles, IconButton, Link, makeStyles, Theme, Tooltip, Button} from "@material-ui/core";
import {IssuesContext, IContextProps} from "../../../context/issues/IssuesContext";
import {EmailVerificationModel} from "../../instagram/page-components/EmailVerificationModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    pointer: {
      cursor: 'pointer',
      color: '#09701c'
    }
  }),
);


interface IProps {
  issues: IssueItem[];
  accountType: AccountType;
}

export const PendingIssues = (props: IProps) => {
  const classes = useStyles();
  const {issues, accountType} = props;
  const {resolveIssues, resolvingIssue} = React.useContext<IContextProps>(IssuesContext);
  const [verify, setVerify] = React.useState<boolean>(false);
  const [challengeUrl, setChallengeUrl] = React.useState<string>('');
  const [verificationId, setVerificationID] = React.useState<string>('');
  const [jsonData, setJsonData] = React.useState<object | null>(null);
  const [resolveId, setResolveId] = React.useState<any>();

  function onResolveCancel() {
    setResolveId(null);
    setJsonData(null);
  }

  function onResolve() {
    resolveIssues(accountType, 'pending', resolveId);
    setResolveId(null);
  }

  function toggleVerification(data?: { id: string, challengeUrl: string }) {
    if (!data) {
      setVerify(false);
      setChallengeUrl('');
      setVerificationID('');
      return;
    }
    const {challengeUrl, id} = data;
    setVerify(true);
    setVerificationID(id);
    setChallengeUrl(challengeUrl);
  }

  const data: any[][] = [];
  if (issues && issues.length) {
    issues.map((row: IssueItem, index) => {
      const singleRow: any[] = [];
      singleRow.push(index + 1);
      singleRow.push(row.id);
      singleRow.push(issueTypeMapper(row.type));
      singleRow.push(
        <Link
          className={classes.pointer}
          onClick={() => setJsonData(row.data)}
        >
          View data
        </Link>);
      singleRow.push(issueReasonsMapper(row.reason));
      if (accountType === "instagram") {
        singleRow.push(row.reason === 'EMAIL_VERIFICATION' ?
          <Button type="button" onClick={() => toggleVerification({
            id: row.data.accountId,
            challengeUrl: row.data.challengeUrl
          })}>Verify</Button>
          : 'N/A');
      }
      singleRow.push(row.status);
      singleRow.push(dateFormat(row.updatedAt));
      console.log(row.data);
      singleRow.push(
        <Tooltip title="Resolve">
          <IconButton
            aria-label="Resolve"
            onClick={() => setResolveId(row.id)}>
            <DoneOutline
              key={uniqueId()}
              className={classes.pointer}
              color={'secondary'}
            />
          </IconButton>
        </Tooltip>
      );
      data.push(singleRow);
      return null;
    });
  }
  return (
    <div className={classes.root}>
      {resolvingIssue && <IndeterminentProgress/>}
      <PaginatedTabel
        data={data}
        header={['#', 'Id', 'Type', 'Data', 'Reason', 'Status', 'Updated At', 'Resolve']}
      />
      {!!resolveId &&
	  <AlertDialog
		  heading={'Are you sure?'}
		  message="You are marking this issue as resolved, you might not be able to undo it?"
		  onCancel={onResolveCancel}
		  onAccept={onResolve}
	  />
      }
      {!!verify &&
	  <EmailVerificationModel challengeUrl={challengeUrl} id={verificationId} onClose={() => toggleVerification()}/>}
      {!!jsonData &&
	  <AlertDialog
		  heading={'Data'}
		  isJsonViewer={true}
		  json={jsonData}
		  onCancel={onResolveCancel}
		  noButton={'Close'}
	  />
      }
    </div>
  );
};
