import React from 'react'
import CloseIcon from '@material-ui/icons/Close';

import {
  createStyles,
  Theme,
  makeStyles,
  Chip,
  Link,
  LinearProgress,
  Button, TextField, Dialog, AppBar, IconButton, Toolbar, Typography, Slide, Badge, Grid
} from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'

import {
  AccountsContext,
  IContextProps
} from '../../context/account/AccountContext'
import {
  AlertDialog,
  IndeterminentProgress, ISwitch,
  PaginatedTabel
} from '../../components'
import {
  DrawerContext,
  IContextProps as DrawerContextProps
} from '../../context/ui-context/DrawerContext'
import { dateFormat, uniqueId } from '../../utils/helpers'
import { TransitionProps } from "@material-ui/core/transitions";
import { SlideProps } from "@material-ui/core/Slide";
import { CreateInstagram } from "./CreateInstagram";
import { EditInstagram } from "./EditInstagram";
import { getAccountsHealthReport, getSomeTrackablityReport } from '../../../services/account';
import MismatchedAccounts from './MismatchedAccounts';

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    table: {
      minWidth: 650
    },
    chip: {
      margin: theme.spacing(1)
    },
    pointer: {
      cursor: 'pointer'
    },
    formControl: {
      minWidth: 300
    },
    flux: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    cleanbtn: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      marginBottom: '15px'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40%',
    },
    head: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    container: {
      padding: 20,
    },
    rowVerticalPadding: {
      paddingTop: 20,
      paddingBottom: 20,
    }
  })
)

interface CleaningAccounts {
  progress: string[]
  finished: string[]
}

export const InstagramListing = () => {
  const classes = useStyles()
  const { setActivePage } = React.useContext<DrawerContextProps>(DrawerContext)

  setActivePage('instagram-view', 'Instagram')

  const {
    loading,
    fetchAccounts,
    sortFilter,
    setSortFilter,
    instagramAccounts,
    removeAccount,
    removing,
    onToggleCheck,
    enableDisableAllAcc,
    setSearch,
    search,
    updateAccount,
  } = React.useContext<IContextProps>(AccountsContext)
  const [deleteId, setDeleteId] = React.useState<string | number | null>('')
  const [addPopup, setAddPopup] = React.useState<boolean>(false)
  const [healthCheckShow, setHealthCheckShow] = React.useState<boolean>(false)
  const [trackbilityCheckShow, setTrackabilityCheckShow] = React.useState<boolean>(false)
  const [healthCheckResponse, setHealthCheckResponse] = React.useState<any>(false)
  const [trackablityCheckResponse, setTrackablityCheckResponse] = React.useState<any>(false)
  const [editAccount, setEditAccount] = React.useState<InstagramAccounts | null>(null)

  const iAccounts = instagramAccounts()
  React.useEffect(() => {
    fetchAccounts('instagram')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onDelete() {
    const id = deleteId
    setDeleteId(null)
    removeAccount('instagram', id)
  }

  function accountsHealthCheck() {
    setHealthCheckShow(true);
    getAccountsHealthReport('instagram')
      .then(({ data }) => {
        console.log('data ayo', data);
        setHealthCheckResponse({
          success: true,
          data,
        });
      })
      .catch((error) => {
        console.log(error);
        setHealthCheckResponse({
          succes: false,
          error: error.toString()
        });
      })
      // .finally(() => setHealthCheckResponse(false));
  }
  function accountsTrackablityCheck() {
    setTrackabilityCheckShow(true);
    getSomeTrackablityReport('instagram')
      .then(({ data }) => {
        console.log('track data ayo', data);
        setTrackablityCheckResponse({
          success: true,
          data,
        });
      })
      .catch((error) => {
        console.log(error);
        setTrackablityCheckResponse({
          succes: false,
          error: error.toString(),
        });
      })
      // .finally(() => setHealthCheckResponse(false));
  }

  function onUpdateSortFilter(filter?: {
    key: string
    direction: 'asc' | 'desc'
  }) {
    setSortFilter(filter)
  }

  function onDeleteCancel() {
    setDeleteId(null)
  }

  function onToggleChk(data: { id: any, checked: boolean }) {
    const { checked, id } = data;
    onToggleCheck({ id, checked, platform: 'instagram' });
  }

  function onEnable(e: any) {
    e.preventDefault();
    enableDisableAllAcc({ enable: true, platform: 'instagram' })
  }

  function onDisable(e: any) {
    e.preventDefault();
    enableDisableAllAcc({ enable: false, platform: 'instagram' })
  }

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.currentTarget.value)
  }

  function onCloseEdit(account?: InstagramAccounts) {
    if (account) {
      updateAccount({ account, platform: "instagram" })
    }
    setEditAccount(null);
  }

  const data: any[][] = []
  if (iAccounts && iAccounts.length) {
    iAccounts.map((row: InstagramAccounts, index) => {
      const singleRow: any[] = []
      const IATAS: any[] = []
      row.iatas.map((item: any) => {
        IATAS.push(
          <Chip key={uniqueId()} label={item} className={classes.chip} />
        )
        return null
      })
      singleRow.push(index + 1)
      singleRow.push(<div key={uniqueId()}>{IATAS}</div>)
      singleRow.push(row.adpName ? row.adpName : 'N/A')
      singleRow.push(row.username ? row.username : 'N/A')
      singleRow.push(row.accountType ? row.accountType : 'N/A')
      singleRow.push(row.accessToken ? "Yes" : 'No')
      singleRow.push(
        row.trelloCardId ? <Link
          key={uniqueId()}
          href={`https://trello.com/c/${row.trelloCardId}`}
          target={"_blank"}
        >
          {row.trelloCardId}
        </Link>: null
      );
      singleRow.push(dateFormat(row.createdAt))
      singleRow.push(<ISwitch checked={!!row.isActive} id={row.id} onChange={onToggleChk} />)
      singleRow.push(<div key={uniqueId()}>{row.trackable ? 'YES' : 'NO'}</div>);
      singleRow.push(
        !row.accessToken ? (
          <Link
            key={uniqueId()}
            href={`https://api.instagram.com/oauth/authorize?client_id=545734847060500&redirect_uri=https://sometracker.staycircles.com/instagram/oauth&scope=user_profile,user_media&response_type=code&state=${row.username}`}
            target={"_blank"}
          >
            Start linking
          </Link>
        ) : null
      );
      singleRow.push(
        <Edit
          key={uniqueId()}
          className={classes.pointer}
          color={'primary'}
          onClick={() => setEditAccount(row)}
        />
      )
      singleRow.push(
        <Delete
          key={uniqueId()}
          className={classes.pointer}
          color={'secondary'}
          onClick={() => setDeleteId(row.id)}
        />
      )
      data.push(singleRow)
      return null
    })
  }

  return (
    <div className={classes.root}>
      {loading && <LinearProgress key={uniqueId()} color="secondary" />}
      {removing && <IndeterminentProgress key={uniqueId()} />}

      <div className={classes.head}>
        <TextField
          autoComplete={"off"}
          label="ADP Name"
          className={classes.textField}
          helperText="Search by adp name"
          margin="normal"
          name="search"
          type="text"
          value={search}
          onChange={onSearchChange}
          autoFocus={true}
          disabled={loading}
        />
        <Button
          type={"button"}
          onClick={onEnable}
          disabled={loading || !(iAccounts && iAccounts.length)}
          color={"primary"}
        >
          Enable All
        </Button>
        <Button
          type={"button"}
          onClick={onDisable}
          disabled={loading || !(iAccounts && iAccounts.length)}
          color={"secondary"}
        >
          Disable All
        </Button>
        <Button
          type={"button"}
          onClick={() => setAddPopup(true)}
          disabled={loading}
          color={"secondary"}
        >
          Add New
        </Button>
        <Button
          type={"button"}
          onClick={() => accountsHealthCheck()}
          disabled={loading}
          color={"secondary"}
        >
          Autoposter Check
        </Button>
        <Button
          type={"button"}
          onClick={() => accountsTrackablityCheck()}
          disabled={loading}
          color={"secondary"}
        >
          SomeTrackablity Checker
        </Button>
      </div>
      <PaginatedTabel
        data={data}
        onUpdateSortFilter={onUpdateSortFilter}
        currentFilter={sortFilter}
        sortableHeader={[
          { label: "#", key: "", sortable: false },
          { label: "IATAs", key: "iatas", sortable: true },
          { label: "ADP Name", key: "adpName", sortable: true },
          { label: "User Name", key: "username", sortable: true },
          { label: "Account Type", key: "accountType", sortable: true },
          { label: "Is Linked", key: "accessToken", sortable: true },
          { label: "Trello Card", key: "trelloCardId", sortable: true },
          { label: "Created at", key: "createdAt", sortable: true },
          { label: "Status", key: "isActive", sortable: false },
          { label: "SomeTrackable", key: "trackable", sortable: true },
          { label: "Link", key: "", sortable: false },
          { label: "Edit", key: "", sortable: false },
          { label: "Delete", key: "", sortable: false },
        ]}
      />
      {!!deleteId && (
        <AlertDialog
          heading={"Are you sure?"}
          message="You are deleting this account, you might not be able to recover it again?"
          onCancel={onDeleteCancel}
          onAccept={onDelete}
        />
      )}
      {!!addPopup && (
        <Dialog
          fullScreen
          open={true}
          onClose={() => setAddPopup(false)}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setAddPopup(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Add Instagram Account
              </Typography>
            </Toolbar>
          </AppBar>
          <CreateInstagram />
        </Dialog>
      )}
      {!!healthCheckShow && (
        <Dialog
          fullScreen
          open={true}
          onClose={() => setHealthCheckShow(false)}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setHealthCheckResponse(false);
                  setHealthCheckShow(false);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Autoposter accounts health check
              </Typography>
            </Toolbar>
          </AppBar>
          {healthCheckResponse && healthCheckResponse.success && (
            <Grid container spacing={3} className={classes.container}>
              {/* <Grid item className={classes.container} xs={12}>
                <Badge
                  badgeContent={healthCheckResponse.data.withPageId.length}
                  color="primary"
                >
                  <Typography variant="h5" component="h5">
                    With Page Id
                  </Typography>
                </Badge>
                <hr />
                {healthCheckResponse.data.withPageId.map((s: any, i: any) => (
                  <Chip
                    label={s.username}
                    clickable
                    color="primary"
                    // onDelete={handleDelete}
                    // deleteIcon={<DoneIcon />}
                    // />
                  />
                ))}
              </Grid> */}
              <Grid className={classes.container} item xs={12}>
                <Badge
                  max={999}
                  badgeContent={
                    healthCheckResponse.data.postableAccounts.length
                  }
                  color="primary"
                >
                  <Typography variant="h5" component="h5">
                    Postable accounts
                  </Typography>
                </Badge>
                <hr />
                {healthCheckResponse.data.postableAccounts.map(
                  (s: any, i: any) => (
                    <Chip
                      label={`${s.username} (${s.pageId}) (${s.facebookUser})`}
                      clickable
                      color="primary"
                      // onDelete={handleDelete}
                      // deleteIcon={<DoneIcon />}
                      // />
                    />
                  )
                )}
              </Grid>
              {/* <Grid className={classes.container} item xs={12}>
                <Badge
                  badgeContent={healthCheckResponse.data.withoutPageId.length}
                  color="primary"
                >
                  <Typography variant="h5" component="h5">
                    Without PageId
                  </Typography>
                </Badge>
                <hr />
                {healthCheckResponse.data.withoutPageId.map(
                  (s: any, i: any) => (
                    <Chip
                      label={s.username}
                      clickable
                      color="primary"
                      onClick={() => {
                        setEditAccount(s);
                      }}
                      // onDelete={handleDelete}
                      // deleteIcon={<DoneIcon />}
                      // />
                    />
                  )
                )}
              </Grid> */}
              <Grid className={classes.container} item xs={12}>
                <Badge
                  max={999}
                  badgeContent={
                    healthCheckResponse.data.mismatchedAccounts.length
                  }
                  color="primary"
                >
                  <Typography variant="h5" component="h5">
                    Mismatched Setup (Needs action)
                  </Typography>
                </Badge>
                <hr />
                <MismatchedAccounts
                  editOnApd={setEditAccount}
                  accounts={healthCheckResponse.data.mismatchedAccounts}
                />
                {/* {healthCheckResponse.data.mismatchedAccounts.map(
                  (s: any, i: any) => (
                    <Chip
                      label={`${s.username}: ${s.reason}`}
                      clickable
                      color="secondary"
                      // onDelete={handleDelete}
                      // deleteIcon={<DoneIcon />}
                      // />
                    />
                  )
                )} */}
              </Grid>
            </Grid>
          )}
          {!healthCheckResponse && (
            <>
              Please wait...This might take long time
              <IndeterminentProgress />
            </>
          )}
          {healthCheckResponse && !healthCheckResponse.success && (
            <>
              <Typography variant="h5" component="h5">
                Failed: {healthCheckResponse.error || "Something went wrong"}
              </Typography>
            </>
          )}
        </Dialog>
      )}
      {!!trackbilityCheckShow && (
        <Dialog
          fullScreen
          open={true}
          onClose={() => setTrackabilityCheckShow(false)}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setTrackablityCheckResponse(false);
                  setTrackabilityCheckShow(false);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                SomeTracker accounts trackablity check
              </Typography>
            </Toolbar>
          </AppBar>
          {trackablityCheckResponse && trackablityCheckResponse.success && (
            <Grid container spacing={3} className={classes.container}>
              {/* <Grid item className={classes.container} xs={12}>
                <Badge
                  badgeContent={healthCheckResponse.data.withPageId.length}
                  color="primary"
                >
                  <Typography variant="h5" component="h5">
                    With Page Id
                  </Typography>
                </Badge>
                <hr />
                {healthCheckResponse.data.withPageId.map((s: any, i: any) => (
                  <Chip
                    label={s.username}
                    clickable
                    color="primary"
                    // onDelete={handleDelete}
                    // deleteIcon={<DoneIcon />}
                    // />
                  />
                ))}
              </Grid> */}
              <Grid className={classes.container} item xs={12}>
                <Badge
                  max={999}
                  badgeContent={
                    trackablityCheckResponse.data.trackableAccounts.length
                  }
                  color="primary"
                >
                  <Typography variant="h5" component="h5">
                    Trackable accounts
                  </Typography>
                </Badge>
                <hr />
                {trackablityCheckResponse.data.trackableAccounts.map(
                  (s: any, i: any) => (
                    <Chip
                      label={`${s.username} (followers: ${s.profile &&
                        s.profile.followers_count}) (totalPosts: ${s.profile &&
                        s.profile.media_count})`}
                      clickable
                      color="primary"
                      // onDelete={handleDelete}
                      // deleteIcon={<DoneIcon />}
                      // />
                    />
                  )
                )}
              </Grid>
              {/* <Grid className={classes.container} item xs={12}>
                <Badge
                  badgeContent={healthCheckResponse.data.withoutPageId.length}
                  color="primary"
                >
                  <Typography variant="h5" component="h5">
                    Without PageId
                  </Typography>
                </Badge>
                <hr />
                {healthCheckResponse.data.withoutPageId.map(
                  (s: any, i: any) => (
                    <Chip
                      label={s.username}
                      clickable
                      color="primary"
                      onClick={() => {
                        setEditAccount(s);
                      }}
                      // onDelete={handleDelete}
                      // deleteIcon={<DoneIcon />}
                      // />
                    />
                  )
                )}
              </Grid> */}
              <Grid className={classes.container} item xs={12}>
                <Badge
                  max={999}
                  badgeContent={
                    trackablityCheckResponse.data.mismatchedAccounts.length
                  }
                  color="primary"
                >
                  <Typography variant="h5" component="h5">
                    Untrackable accounts
                  </Typography>
                </Badge>
                <hr />
                {trackablityCheckResponse.data.mismatchedAccounts.map(
                  (s: any, i: any) => (
                    <Chip
                      label={`${s.username}: ${s.reason}`}
                      clickable
                      color="secondary"
                      // onDelete={handleDelete}
                      // deleteIcon={<DoneIcon />}
                      // />
                    />
                  )
                )}
              </Grid>
            </Grid>
          )}
          {!trackablityCheckResponse && (
            <>
              Please wait...This might take long time
              <IndeterminentProgress />
            </>
          )}
          {trackablityCheckResponse && !trackablityCheckResponse.success && (
            <>
              <Typography variant="h5" component="h5">
                Failed: {healthCheckResponse.error || "Something went wrong"}
              </Typography>
            </>
          )}
        </Dialog>
      )}
      {!!editAccount && (
        <Dialog
          fullScreen
          open={true}
          onClose={() => onCloseEdit()}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => onCloseEdit()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Edit Instagram Account
              </Typography>
            </Toolbar>
          </AppBar>
          <EditInstagram account={editAccount} onClose={onCloseEdit} />
        </Dialog>
      )}
    </div>
  );
}
