import * as React from 'react';
import {getAccountsManual, updateManualClean} from '../../../services';

interface PaginatedResponse {
  totalItemsCount: number;
  remainingItems: number;
  result: InstagramAccounts[];
}

export interface IContextProps {
  instagramAccounts(showCleaned: boolean, showNotCleaned: boolean): InstagramAccounts[];

  fetchAccounts(arg: AccountType, page: number): void;

  setSearch(query?: string): void;

  updateItem(acc: InstagramAccounts): void;

  onUpdateClean(cleaned: boolean, id: string): void;

  lastLimitId: number;
  totalItemsCount: number;
  remainingItems: number;
  loading: boolean;
  search: string | undefined;
}

type AccountType =
  | 'reddit' | 'twitter' | 'instagram' | 'facebook' | 'postNews' | 'threads';

export const ManualCPContext = React.createContext({} as IContextProps);

export const ManualCPContextProvider = ({children}: any) => {

  const [instagramAcc, setInstagramAccounts] = React.useState<InstagramAccounts[]>([]);
  const [lastLimitId, setLastLimitId] = React.useState<number>(0);
  const [lastPage, setLastPage] = React.useState<number>(0);
  const [totalItemsCount, setTotalItemsCount] = React.useState<number>(0);
  const [remainingItems, setRemainingItems] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState<string | undefined>();

  function updateItem(acc: InstagramAccounts) {
    const accounts = instagramAcc.map((oldac) => {
      if (oldac.id === acc.id) {
        if (oldac.relatedAcc) return {...acc, relatedAcc: oldac.relatedAcc};
        return acc;
      }
      return oldac;
    });
    setInstagramAccounts(accounts);
  }

  function onUpdateClean(cleaned: boolean, id: string) {
    setLoading(true);
    updateManualClean({cleaned, id})
      .then(({data}) => {
        if (data) {
          updateItem({...data});
        }
      })
      .catch(console.log)
      .finally(() => setLoading(false))
  }

  function fetchAccounts(arg: AccountType, page: number) {

    if (page > lastPage) {
      setLastPage(page);
      if (instagramAcc.length >= lastLimitId + 20) {
        setLastLimitId(lastLimitId + 20);
        return;
      }
    }

    if (page < lastPage) {
      setLastPage(page);
      setLastLimitId(lastLimitId - 20);
      return;
    }

    setLoading(true);
    getAccountsManual({platform: arg, lastLimitId})
      .then(({data}) => {
        const {remainingItems, totalItemsCount, result}: PaginatedResponse = data;
        setLastLimitId(lastLimitId + 20);
        setRemainingItems(remainingItems);
        setTotalItemsCount(totalItemsCount);
        if (result && result.length) {
          setInstagramAccounts([...instagramAcc, ...result]);
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => setLoading(false));
  }

  function instagramAccounts(showCleaned: boolean, showNotCleaned: boolean) {
    let acc: InstagramAccounts[] = instagramAcc;
    if (search) acc = acc.filter(item => item.email.toLowerCase().includes(search.toLowerCase()) || item.username.toLowerCase().includes(search.toLowerCase()))
    if (showCleaned && showNotCleaned) return acc.slice(lastLimitId - 20, lastLimitId);
    if (!showCleaned) {
      acc = acc.filter(ac => !ac.cleaned)
    }
    if (!showNotCleaned) {
      acc = acc.filter(ac => !!ac.cleaned)
    }

    return acc.slice(lastLimitId - 20, lastLimitId)
  }

  const defaultContext = {
    instagramAccounts,
    fetchAccounts,
    loading,
    setSearch,
    search,
    lastLimitId,
    totalItemsCount,
    remainingItems,
    updateItem,
    onUpdateClean,
  };

  return (
    <ManualCPContext.Provider value={defaultContext}>
      {children}
    </ManualCPContext.Provider>
  );
};