import * as React from 'react';
import { IndeterminentProgress, ProgressButton, SnackBar, TagsInput } from "../../components";
import { makeStyles, Paper, TextField } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { toast } from "react-toastify"

import { ChipData } from "../../components/tags-input/TagsInput";
import { createAccount } from '../../../services/account';
import { addProfileURL } from '../../../services/some';
import { IContextProps, DrawerContext } from '../../context/ui-context/DrawerContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexFlow: 'column wrap',
      padding: theme.spacing(0.5),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40%',
    },
    heading: {
      marginLeft: theme.spacing(1),
    },
    control: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '20px'
    }
  }),
);

interface IProps {
}

export const CreateThreads = (props: IProps) => {
  const { setActivePage } = React.useContext<IContextProps>(DrawerContext);
  setActivePage('threads-add', 'Add Threads Account');
  const [chips, setChips] = React.useState<{ tags: ChipData[] }>({ tags: [] });
  const formRef: any = React.useRef<any>();
  const [formState, setFormState] = React.useState<any>({});
  const [hashTags] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const classes = useStyles();


  const onChangeTags = (tags: ChipData[]) => {
    setChips({ tags });
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    e.preventDefault();
    setFormState({ ...formState, [e.currentTarget.name]: e.currentTarget.value });
  }

  // const addUrlToSomeTracker = () => {
  //   addProfileURL(formState.url)
  //     .then(({ status }: { status: any }) => {
  //       if (status === 200) {
  //         toast.success('Added group url to SoMe-tracker')
  //       }
  //     })
  //     .catch((error : any) => {
  //       toast.error('Could not add group url to SoMe-tracker')
  //     })
  // }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    // addUrlToSomeTracker()
    const tags: string[] = [];
    chips.tags.map(tag => tags.push(tag.label));
    const args: any = {
      platform: 'threads',
      iatas: tags,
      url: formState.url ? formState.url : '',
      hashTags,
      ...formState
    };

    createAccount(args)
      .then(({ status }) => {
        if (status) {
          setChips({ tags: [] });
          setFormState({});
          formRef.current.reset();
          setMessage('Threads account Saved Successfully');
          setShowMessage(true);
        }
      })
      .catch((error) => {
        console.log('error', error)
        setMessage(error?.response?.data?.error ||  'Something went wrong');
        setShowMessage(true);
      })
      .finally(() => setLoading(false))
  }

  return (
    <Paper className={classes.root}>
      <h3 className={classes.heading}>Add threads account</h3>
      {!!loading && <IndeterminentProgress />}
      <form onSubmit={handleSubmit} ref={formRef} noValidate={true}>
        <TagsInput
          required={!(chips && chips.tags && chips.tags.length)}
          onChange={onChangeTags}
          tags={chips.tags}
        />
        {/* <TextField
          autoComplete={"off"}
          label="URL"
          className={classes.textField}
          helperText="Enter url"
          margin="normal"
          name="url"
          type="url"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        /> */}
        <TextField
          autoComplete={"off"}
          label="Unique Name"
          className={classes.textField}
          helperText="Enter Unique Name"
          margin="normal"
          name="uniqueName"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.uniqueName}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Username"
          className={classes.textField}
          helperText="Enter username"
          margin="normal"
          name="username"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        />
        <TextField
          autoComplete={"off"}
          label="User Id"
          className={classes.textField}
          helperText="Enter User Id"
          margin="normal"
          name="userId"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          value={formState.userId}
          required
        />
        <TextField
          autoComplete={"off"}
          label="Access Token"
          className={classes.textField}
          helperText="Enter access token. You can get Access Token and Access Token Secret here: https://threads.net/oauth/authorize?client_id=801554835066996&scope=threads_basic,threads_content_publish&response_type=code&redirect_uri=https://apd.staycircles.com/threads/auth"
          margin="normal"
          name="accessToken"
          type="text"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
        />
        <TextField
          autoComplete={"off"}
          label="Hash Tags"
          className={classes.textField}
          helperText="Enter hash tags by comma separated"
          margin="normal"
          name="hashTags"
          type="text"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
        />
        <TextField
          autoComplete={"off"}
          label="Trello Card Id"
          className={classes.textField}
          helperText="Trello Card Id"
          margin="normal"
          name="trelloCardId"
          onChange={handleChange}
          autoFocus={true}
          disabled={loading}
          required
        />

        <div className={classes.control}>
          <ProgressButton title={"Save"} type={"submit"} loading={loading} />
        </div>
      </form>
      {!!showMessage && (
        <SnackBar message={message} onClose={() => setShowMessage(false)} />
      )}
    </Paper>
  );
};
