import * as React from 'react';
import { ProgressButton, SnackBar, TagsInput } from "../../components";
import { makeStyles, Paper, TextField } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { toast } from "react-toastify"

import { ChipData } from "../../components/tags-input/TagsInput";
import { createAccount } from '../../../services/account';
import { addProfileURL } from '../../../services/some';
import { validURL } from '../../utils/helpers'
import { IContextProps, DrawerContext } from '../../context/ui-context/DrawerContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexFlow: 'column wrap',
      padding: theme.spacing(0.5),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40%',
    },
    heading: {
      marginLeft: theme.spacing(1),
    },
    control: {
      display: 'flex',
      justifyContent: 'center',
    }
  }),
);

interface IProps {
}

export const CreateReddit = (props: IProps) => {
  const { setActivePage } = React.useContext<IContextProps>(DrawerContext);
  setActivePage('reddit-add', 'Add Reddit account');
  const [state, setState] = React.useState<{
    tags: ChipData[];
    url: string;
    branchUrl: string;
    uniqueName: string;
    trelloCardId: string;
    refreshToken: string;
  }>({
    tags: [],
    url: "",
    branchUrl: "",
    uniqueName: "",
    trelloCardId: "",
    refreshToken: "",
  });
  const [tagsError, setTagsError] = React.useState<boolean>(false);
  const [urlError, setUrlError] = React.useState<boolean>(false);
  const [branchUrlError, setBranchUrlError] = React.useState<boolean>(false);
  const [uniqueNameError, setUniqueNameError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const classes = useStyles();

  const validate = () => {
    let isValid: boolean = true;
    if (!state.tags.length) {
      isValid = false;
      setTagsError(true);
    } else {
      setTagsError(false);
    }
    if (!state.url) {
      isValid = false;
      setUrlError(true);
    } else {
      if (validURL(state.url)) {
        setUrlError(false);
      } else {
        isValid = false;
        setUrlError(true);
      }
    }

    if (!state.branchUrl) {
      isValid = false;
      setBranchUrlError(true);
    } else {
      setBranchUrlError(false);
    }

    if (!state.uniqueName) {
      isValid = false;
      setUniqueNameError(true);
    } else {
      setUniqueNameError(false);
    }
    return isValid;

  };

  const onChange = (e: any) => {
    setState({ ...state, url: e.target.value });
    validate()
  };

  const onChangeBranch = (e: any) => {
    setState({ ...state, branchUrl: e.target.value });
    validate()
  };
  const onRefreshTokenChange = (e: any) => {
    setState({ ...state, refreshToken: e.target.value });
    validate();
  };
  const onTrelloIdChange = (e: any) => {
    setState({ ...state, trelloCardId: e.target.value });
    validate()
  };

  const onChangeUniqueName = (e: any) => {
    setState({ ...state, uniqueName: e.target.value });
    validate()
  };

  const onChangeTags = (tags: ChipData[]) => {
    setState({ ...state, tags });
    validate();
  };

  const addUrlToSomeTracker = () => {
    addProfileURL(state.url)
      .then(({ status }: { status: any }) => {
        if (status === 200) {
          toast.success('Added profile url to SoMe-tracker')
        }
      })
      .catch((error: any) => {
        toast.error('Could not add profile url to SoMe-tracker')
      })
  }

  const onSubmit = () => {
    if (validate()) {
      setLoading(true);
      addUrlToSomeTracker()
      const tags: string[] = [];
      state.tags.map((tag: ChipData) => tags.push(tag.label));
      createAccount({ ...state, platform: 'reddit', iatas: tags, url: state.url })
        .then(({ status }) => {
          if (status) {
            setState({ tags: [], url: '', uniqueName: '', branchUrl: '', trelloCardId: '', refreshToken: '' });
            setMessage('Reddti Saved Successfully')
            setShowMessage(true);
          }
        })
        .catch((error) => {
          setMessage('Something went wrong');
          setShowMessage(true);
        })
        .finally(() => setLoading(false))
    }
  };


  return (
    <Paper className={classes.root}>
      <h3 className={classes.heading}>Add Reddit account</h3>
      <TagsInput onChange={onChangeTags} error={tagsError} tags={state.tags} />
      <TextField
        label="Unique ADP Name"
        id="margin-normal"
        className={classes.textField}
        helperText="Enter unique ADP name"
        margin="normal"
        onChange={onChangeUniqueName}
        value={state.uniqueName}
        autoFocus={true}
        error={uniqueNameError}
        disabled={loading}
      />
      <TextField
        label="URL"
        id="margin-normal"
        className={classes.textField}
        helperText="Enter url"
        margin="normal"
        onChange={onChange}
        value={state.url}
        autoFocus={true}
        error={urlError}
        disabled={loading}
      />
      <TextField
        label="Branch Url"
        id="margin-normal"
        className={classes.textField}
        helperText="Enter branch url"
        margin="normal"
        onChange={onChangeBranch}
        value={state.branchUrl}
        autoFocus={true}
        error={branchUrlError}
        disabled={loading}
      />
      <TextField
        label="Refresh Token"
        id="margin-normal"
        className={classes.textField}
        helperText="Enter account refresh token"
        margin="normal"
        onChange={onRefreshTokenChange}
        value={state.refreshToken}
        autoFocus={true}
        disabled={loading}
      />
      <TextField
        label="Trello Card Id"
        id="margin-normal"
        className={classes.textField}
        helperText="Enter Trello Card Id"
        margin="normal"
        onChange={onTrelloIdChange}
        value={state.trelloCardId}
        autoFocus={true}
        // error={branchUrlError}
        disabled={loading}
      />
      <div className={classes.control}>
        <ProgressButton title={"Save"} loading={loading} onClick={onSubmit} />
      </div>
      {!!showMessage && (
        <SnackBar message={message} onClose={() => setShowMessage(false)} />
      )}
    </Paper>
  );
};