import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import {NavbarItems} from "./NavbarItems";
import {RouteComponentProps, withRouter} from "react-router";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

interface IProps extends RouteComponentProps {
  open: boolean;

  onClose: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      minWidth: '300px',
      width: '500px'
    },
  }),
);
const Draw = (props: IProps) => {
  const classes = useStyles();
  const {open, onClose} = props;
  const onCloseDrawer = () => {
    if (onClose) onClose()
  };
  const {location} = props;
  return (
    <Drawer open={open}  className={classes.drawer} onClose={onCloseDrawer}>
      <NavbarItems currentRoute={location.pathname}/>
    </Drawer>
  );
}

export const AppDrawer = withRouter(Draw);