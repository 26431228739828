import axios from 'axios'
import { readStorage } from '../../client/utils/helpers'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:8080'
}

export const getSetupInfo = () => {
  return axios.get(`/instagram/setup-info`, { auth: readStorage('user', true) })
}

interface IGAccount {
  username: string
  password: string
  proxy: string
}

export interface EditSetupInfoProps {
  account: IGAccount
  queueMinDelayMinutes: string | number
  queueMaxDelayMinutes: string | number
  queuePauseStartHour: string | number
  queuePauseEndHour: string | number
}

export const editSetupInfo = (args: EditSetupInfoProps) => {
  return axios.post('/instagram/setup-info', args, {
    auth: readStorage('user', true)
  })
}
