import * as React from 'react';
import {deleteAccount, enableDisableAll, getAccounts} from '../../../services';
import {orderBy} from 'lodash';
import {editAccount} from "../../../services/account";

export interface IContextProps {
  redditAccounts(): RedditAccount[];

  instagramAccounts(): InstagramAccounts[];

  twitterAccounts(): TwitterAccounts[];
  
  facebookAccounts(): FacebookAccounts[];

  threadsAccounts(): ThreadsAccounts[];

  postNewsAccounts(): PostNewsAccounts[];

  updateFilter(filter: any): void;

  fetchAccounts(arg: AccountType, lastLimitId?: number): void;

  fetchSingleAccount(arg: AccountType, id: string): void;

  removeAccount(platform: AccountType, id: any): void;

  onToggleCheck(data: { id: any, checked: boolean, platform: AccountType }): void;

  enableDisableAllAcc(data: { platform: AccountType, enable: boolean }): void;

  setSearch(query?: string): void;

  addSingleAccountToList({account, platform}: { account: any, platform: AccountType }): void;

  updateAccount({account, platform}: { account: any, platform: AccountType }): void;

  sortFilter: { key: string, direction: 'asc' | 'desc' } | undefined;
  setSortFilter: Function;
  loading: boolean;
  removing: boolean;
  singleAccount: any;
  search: string | undefined;
}

type AccountType =
  | 'reddit' | 'twitter' | 'instagram' | 'facebook' | 'postNews' | 'threads';

export const AccountsContext = React.createContext({} as IContextProps);

export const AccountsContextProvider = ({children}: any) => {

  const [sortFilter, setSortFilter] = React.useState<{ key: string, direction: 'asc' | 'desc' } | undefined>();
  const [redditAcc, setRedditAccounts] = React.useState([]);
  const [twitterAcc, setTwitterAccounts] = React.useState([]);
  const [facebookAcc, setFacebookAccounts] = React.useState([]);
  const [instagramAcc, setInstagramAccounts] = React.useState([]);
  const [postNewsAcc, setPostNewsAccounts] = React.useState([]);
  const [threadsAcc, setThreadsAccounts] = React.useState([]);
  const [singleAccount, setSingleAccount] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [removing, setRemoving] = React.useState(false);
  const [filter, setFilter] = React.useState<string>('');
  const [search, setSearch] = React.useState<string | undefined>();

  function updateAccount({account, platform}: { account: any, platform: AccountType }) {
    let func: any;
    let accountsList: any[];
    switch (platform) {
      case 'reddit': {
        func = setRedditAccounts;
        accountsList = redditAcc;
        break;
      }
      case 'twitter': {
        func = setTwitterAccounts;
        accountsList = twitterAcc;
        break;
      }
      case 'facebook': {
        func = setFacebookAccounts;
        accountsList = facebookAcc;
        break;
      }
      case 'instagram': {
        func = setInstagramAccounts;
        accountsList = instagramAcc;
        break;
      }
      case 'postNews': {
        func = setPostNewsAccounts;
        accountsList = postNewsAcc;
        break;
      }
      case 'threads': {
        func = setThreadsAccounts;
        accountsList = threadsAcc;
        break;
      }
      default:
        throw new Error('Unexpected platform type');
    }
    if (func && accountsList) {
      accountsList = accountsList.map((item) => {
        if (item.id === account.id) {
          return {...account}
        }
        return item;
      });
      func(accountsList);
    }

  }

  function addSingleAccountToList({account, platform}: { account: any, platform: AccountType }) {
    let func: any;
    let accountsList: any[];
    switch (platform) {
      case "reddit": {
        func = setRedditAccounts;
        accountsList = redditAcc;
        break;
      }
      case "twitter": {
        func = setTwitterAccounts;
        accountsList = twitterAcc;
        break;
      }
      case "facebook": {
        func = setFacebookAccounts;
        accountsList = facebookAcc;
        break;
      }
      case "instagram": {
        func = setInstagramAccounts;
        accountsList = instagramAcc;
        break;
      }
      case "postNews": {
        func = setPostNewsAccounts;
        accountsList = postNewsAcc;
        break;
      }
      case "threads": {
        func = setThreadsAccounts;
        accountsList = threadsAcc;
        break;
      }
      default:
        throw new Error("Unexpected platform type");
    }
    if (func && accountsList) {
      accountsList.push(account);
      func(accountsList);
    }

  }

  function enableDisableAllAcc(data: { platform: AccountType, enable: boolean }) {
    const {platform} = data;
    setLoading(true);
    enableDisableAll(data)
      .then(({data: resp}) => {
        const {success, error} = resp;
        if (success) {
          fetchAccounts(platform);
        } else if (error) {
          window.alert(error);
        }
      })
      .finally(() => setLoading(false));
  }

  function onToggleCheck(data: { id: any, checked: boolean, platform: AccountType }) {
    const {checked, id, platform} = data;
    let old: any[];
    old = platform === 'instagram' ?
      instagramAcc : platform === 'twitter' ?
        twitterAcc : platform === 'reddit' ?
          redditAcc : platform === 'facebook' ?
          facebookAcc : platform === 'postNews' ?
          postNewsAcc : platform === 'threads' ?
          threadsAcc: [];
    const acc = old.find(item => item.id === id);
    if (acc) {
      const filtered = old.filter(item => item.id !== id);
      filtered.push({...acc, isActive: checked});
      let update: any = platform === 'instagram' ?
        setInstagramAccounts : platform === 'twitter' ?
          setTwitterAccounts : platform === 'reddit' ?
            setRedditAccounts : platform === 'facebook' ? 
            setFacebookAccounts : platform === 'threads' ? 
            setThreadsAccounts : platform === 'postNews' ? setPostNewsAccounts : null;
      if (update) {
        update(orderBy(filtered, ['id'], ['asc']));
      }
      setLoading(true)
      editAccount(
        {
          ...{...acc, isActive: checked},
          platform,
          iatas: acc.iatas,
          id: acc.id
        })
        .then(({status}) => {
          if (status) {
            console.debug('updated acc')
          }
        })
        .finally(() => setLoading(false));
    }
  }

  function fetchAccounts(arg: AccountType, lastLimitId?: number) {
    setSortFilter(undefined);
    setSearch('');
    let func: any;

    switch (arg) {
      case "reddit": {
        func = setRedditAccounts;
        break;
      }
      case "twitter": {
        func = setTwitterAccounts;
        break;
      }
      case "facebook": {
        func = setFacebookAccounts;
        break;
      }
      case "instagram": {
        func = setInstagramAccounts;
        break;
      }
      case "postNews": {
        func = setPostNewsAccounts;
        break;
      }
      case "threads": {
        func = setThreadsAccounts;
        break;
      }
      default:
        throw new Error("Unexpected platform type");
    }
    if (!func) return;
    setLoading(true);
    getAccounts({platform: arg, lastLimitId})
      .then(({data}) => {
        if (data) {
          func(orderBy(data, ['id'], ['asc']));
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => setLoading(false));
  }

  function fetchSingleAccount(args: AccountType, id: string) {
    setLoading(true);
    getAccounts({platform: args, accid: id})
      .then(({data}) => {
        if (data) {
          setSingleAccount(data);
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => setLoading(false));
  }

  function removeAccount(args: AccountType, id: string) {
    setRemoving(true);
    deleteAccount({id, platform: args})
      .then(() => {
        let acc: any =
          args === "twitter"
            ? twitterAcc
            : args === "instagram"
            ? instagramAcc
            : args === "reddit"
            ? redditAcc
            : args === "facebook"
            ? facebookAcc
            : args === 'postNews'
            ? postNewsAcc
            : undefined;
        let update: any =
          args === "twitter" ? setTwitterAccounts :
            args === "instagram" ? setInstagramAccounts :
              args === 'reddit' ? setRedditAccounts :
                args === 'facebook' ? setFacebookAccounts :
                args === 'postNews' ? setPostNewsAccounts : undefined;
        if (!acc || !update) return;
        const filtered = acc.filter((item: any) => item.id !== id);
        update(filtered);
      })
      .catch(console.error)
      .finally(() => setRemoving(false));
  }

  function redditAccounts() {
    let filtereOne;
    if (!filter) {
      filtereOne = redditAcc;
    } else {
      filtereOne = redditAcc.filter((item: RedditAccount) => item.isValidSubReddit === (filter === 'verified'))
    }

    let accs: RedditAccount[] = [];
    if (sortFilter) {
      accs = orderBy(filtereOne, [sortFilter.key], [sortFilter.direction])
    } else {
      accs = redditAcc;
    }
    return search ? accs.filter(item => item.url.toLowerCase().includes(search.toLowerCase())) : accs;
  }

  function updateFilter(filter: any) {
    if (!filter) {
      setFilter('');
    } else {
      setFilter(filter);
    }
  }

  function instagramAccounts() {
    let acc: InstagramAccounts[];
    if (sortFilter) {
      acc = orderBy(instagramAcc, [sortFilter.key], [sortFilter.direction])
    } else {
      acc = instagramAcc;
    }
    if (!search) return acc;
    return acc.filter(item => (item.username && item.username.toLowerCase().includes(search.toLowerCase())) || item.adpName.toLowerCase().includes(search.toLowerCase()) || item.iatas.join(' ').toLowerCase().includes(search.toLowerCase()))
  }

  function twitterAccounts() {
    let accs: TwitterAccounts[];
    if (sortFilter) {
      accs = orderBy(twitterAcc, [sortFilter.key], [sortFilter.direction])
    } else {
      accs = twitterAcc;
    }

    return search ? accs.filter(item => {
      const {iatas = []} = item
      for (const iata of iatas){
        if (iata && iata.toLowerCase().includes(search.toLowerCase())){
          return true
        }
      }
      return item.email && item.email.toLowerCase().includes(search.toLowerCase())
    }) : accs;
  }

  function postNewsAccounts() {
    let accs: PostNewsAccounts[];
    if (sortFilter) {
      accs = orderBy(postNewsAcc, [sortFilter.key], [sortFilter.direction])
    } else {
      accs = postNewsAcc;
    }

    return search ? accs.filter(item => {
      const {iatas = []} = item
      for (const iata of iatas){
        if (iata && iata.toLowerCase().includes(search.toLowerCase())){
          return true
        }
      }
      return item.email && item.email.toLowerCase().includes(search.toLowerCase())
    }) : accs;
  }

  function facebookAccounts() {
    let accs: FacebookAccounts[];
    if (sortFilter) {
      accs = orderBy(facebookAcc, [sortFilter.key], [sortFilter.direction]);
    } else {
      accs = facebookAcc;
    }

    return search
      ? accs.filter((item) => {
          const { iatas = [] } = item;
          for (const iata of iatas) {
            if (iata && iata.toLowerCase().includes(search.toLowerCase())) {
              return true;
            }
          }
          return (
            item.name &&
            item.name.toLowerCase().includes(search.toLowerCase())
          );
        })
      : accs;
  }

   function threadsAccounts() {
     let accs: ThreadsAccounts[];
     if (sortFilter) {
       accs = orderBy(threadsAcc, [sortFilter.key], [sortFilter.direction]);
     } else {
       accs = threadsAcc;
     }

     return search
       ? accs.filter((item) => {
           const { iatas = [] } = item;
           for (const iata of iatas) {
             if (iata && iata.toLowerCase().includes(search.toLowerCase())) {
               return true;
             }
           }
           return (
             item.username && item.username.toLowerCase().includes(search.toLowerCase())
           );
         })
       : accs;
   }

  const defaultContext = {
    redditAccounts,
    twitterAccounts,
    facebookAccounts,
    threadsAccounts,
    instagramAccounts,
    postNewsAccounts,
    fetchAccounts,
    fetchSingleAccount,
    removeAccount,
    loading,
    removing,
    singleAccount,
    updateFilter,
    sortFilter,
    setSortFilter,
    onToggleCheck,
    enableDisableAllAcc,
    setSearch,
    updateAccount,
    addSingleAccountToList,
    search,
  };

  return (
    <AccountsContext.Provider value={defaultContext}>
      {children}
    </AccountsContext.Provider>
  );
};
