import * as React from 'react';
import {DrawerContextProvider} from './ui-context'
import {AccountsContextProvider} from './account/AccountContext'
import {ManualCPContextProvider} from './account/ManualCP'
import {IssuesContextProvider} from './issues/IssuesContext'
import {readStorage, setStorage} from "../utils/helpers";

interface IContextProps {
  isAuth: boolean;

  authenticate(data: { username: string, password: string }): string | undefined;

  logout(): void;
}

export const AppContext = React.createContext({} as IContextProps);

export const AUTH_KEY: string = 'SOME_SUPER_AUTH_KEY';

export const AppContextProvider = ({children}: any) => {

  const [isAuth, setIsAuth] = React.useState<boolean>(readStorage(AUTH_KEY, false));

  function authenticate(data: { username: string, password: string }) {
    if (data.username === 'admin' && data.password === 'superadminsecret') {

      setStorage(AUTH_KEY, true, false);
      setStorage('user', {username: data.username, password: data.password}, true);
      setIsAuth(true);
    } else {

      return 'incorrect username or password';
    }
  }

  function logout() {
    window && window.localStorage.clear();
    setIsAuth(false);
  }

  const defaultContext = {
    isAuth: !!isAuth,
    authenticate,
    logout
  };

  return (
    <AppContext.Provider value={defaultContext}>
      <DrawerContextProvider>
        <AccountsContextProvider>
          <ManualCPContextProvider>
            <IssuesContextProvider>
              {children}
            </IssuesContextProvider>
          </ManualCPContextProvider>
        </AccountsContextProvider>
      </DrawerContextProvider>
    </AppContext.Provider>
  );
};
