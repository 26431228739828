import React, {useState} from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  Paper,
  Tabs,
  Tab,
} from '@material-ui/core';
import {PendingIssues, FailureIssues, ResolvedIssues} from '../reddit/page-components'
import {DrawerContext, IContextProps as DrawerContextProps} from "../../context/ui-context/DrawerContext";
import {IssuesContext, IContextProps} from "../../context/issues/IssuesContext";
import {IndeterminentProgress} from "../../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    chip: {
      margin: theme.spacing(1),
    }
  }),
);

export const TwitterIssues = () => {
  const classes = useStyles();
  const {setActivePage} = React.useContext<DrawerContextProps>(DrawerContext);
  setActivePage('twitter-issues', 'Twitter issues');
  const [activeTab, setActiveTab] = useState<number>(0);
  const {loadingIssues, fetchIssues, issues} = React.useContext<IContextProps>(IssuesContext);

  function handleChange(event: React.ChangeEvent<{}>, newTab: number) {
    setActiveTab(newTab);
  }

  React.useEffect(() => {
    fetchIssues('twitter');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={activeTab}
        indicatorColor="secondary"
        textColor="primary" onChange={handleChange}>
        <Tab label="Pending"/>
        <Tab label="Resolved"/>
        <Tab label="Failure"/>
      </Tabs>
      {loadingIssues && <IndeterminentProgress/>}
      {(issues && activeTab === 0) && <PendingIssues accountType={'twitter'} issues={issues.pending}/>}
      {(issues && activeTab === 1) && <ResolvedIssues issues={issues.resolved}/>}
      {(issues && activeTab === 2) && <FailureIssues issues={issues.failures}/>}
    </Paper>
  );
}