import React from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  Link, Chip, LinearProgress, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button, TextField
} from '@material-ui/core';
import history from '../../utils/history';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { toast } from "react-toastify"

import { removeProfileURL } from '../../../services/some';
import { AccountsContext, IContextProps } from '../../context/account/AccountContext';
import { AlertDialog, IndeterminentProgress, ISwitch, PaginatedTabel } from "../../components";
import { DrawerContext, IContextProps as DrawerContextProps } from "../../context/ui-context/DrawerContext";
import { dateFormat, uniqueId } from "../../utils/helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    chip: {
      margin: theme.spacing(1),
    },
    pointer: {
      cursor: 'pointer',
    },
    formControl: {
      minWidth: 300,
    },
    flux: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40%',
    },
    head: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    }
  }),
);

export const RedditListing = () => {
  const classes = useStyles();
  const { setActivePage } = React.useContext<DrawerContextProps>(DrawerContext);
  setActivePage('reddit-view', 'Reddits');
  const {
    loading,
    fetchAccounts,
    enableDisableAllAcc,
    onToggleCheck,
    sortFilter,
    setSortFilter,
    redditAccounts,
    removeAccount,
    removing,
    updateFilter,
    search,
    setSearch,
  } = React.useContext<IContextProps>(AccountsContext);
  const [deleteId, setDeleteId] = React.useState<string | number | null>('');
  const inputLabel = React.useRef<HTMLLabelElement>(null);

  const [values, setValues] = React.useState({
    filter: '',
  });
  const rAccounts = redditAccounts();
  React.useEffect(() => {
    fetchAccounts('reddit');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onUpdateSortFilter(filter?: { key: string, direction: 'asc' | 'desc' }) {
    setSortFilter(filter);
  }

  const removeUrlFromSomeTracker = () => {
    let ra: any = rAccounts.find((elem) => elem.id === deleteId);
    let url: string = ra['url'];
    removeProfileURL(url)
      .then(({ status }: { status: any }) => {
        if (status === 200) {
          toast.success('Removed reddit profile url from SoMe-tracker')
        }
      })
      .catch((error: any) => {
        toast.error('Could not remove reddit profile url from SoMe-tracker')
      })
  }

  function onDelete() {
    removeUrlFromSomeTracker()
    const id = deleteId;
    setDeleteId(null)
    removeAccount('reddit', id);
  }

  function onDeleteCancel() {
    setDeleteId(null);
  }

  function handleChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name as string]: event.target.value,
    }));
    updateFilter(event.target.value);
  }

  function onToggleChk(data: { id: any, checked: boolean }) {
    const { checked, id } = data;
    onToggleCheck({ id, checked, platform: 'reddit' });
  }

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.currentTarget.value)
  }

  const data: any[][] = [];
  if (rAccounts && rAccounts.length) {
    rAccounts.map((row: RedditAccount, index) => {
      const singleRow: any[] = [];
      const IATAS: any[] = [];
      row.iatas.map((item: any) => {
        IATAS.push(<Chip key={uniqueId()} label={item} className={classes.chip} />);
        return null;
      });
      singleRow.push(<ISwitch checked={!!row.isActive} id={row.id} onChange={onToggleChk} />)
      singleRow.push(index + 1);
      singleRow.push(row.uniqueName);
      singleRow.push(<div key={uniqueId()}>{IATAS}</div>);
      singleRow.push(<Link key={uniqueId()} href={row.url} target={'_blank'}>{row.url}</Link>);
      singleRow.push(<Link key={uniqueId()} href={row.branchUrl} target={'_blank'}>{row.branchUrl}</Link>);
      singleRow.push(
        row.trelloCardId ? (
          <Link
            key={uniqueId()}
            href={`https://trello.com/c/${row.trelloCardId}`}
            target={"_blank"}
          >
            {row.trelloCardId}
          </Link>
        ) : null
      );
      singleRow.push(dateFormat(row.createdAt));
      singleRow.push(
        <Edit
          key={uniqueId()}
          className={classes.pointer}
          color={'primary'}
          onClick={() => history.push(`/dashboard/reddit/${row.id}`)}
        />
      );
      singleRow.push(
        <Delete
          key={uniqueId()}
          className={classes.pointer}
          color={'secondary'}
          onClick={() => setDeleteId(row.id)}
        />
      );
      data.push(singleRow);
      return null;
    });
  }

  return (
    <div className={classes.root}>
      {loading && <LinearProgress key={uniqueId()} color="secondary" />}
      {removing && <IndeterminentProgress key={uniqueId()} />}
      <div className={classes.flux}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
            Select Filter
          </InputLabel>
          <Select
            value={values.filter}
            onChange={handleChange}
            input={
              <OutlinedInput
                labelWidth={200}
                name="filter"
                id="outlined-age-simple"
              />
            }
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"verified"}>Verified</MenuItem>
            <MenuItem value={"failed"}>Failed</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={classes.head}>
        <TextField
          autoComplete={"off"}
          label="URL"
          className={classes.textField}
          helperText="Search by url"
          margin="normal"
          name="search"
          type="text"
          value={search}
          onChange={onSearchChange}
          autoFocus={true}
          disabled={loading}
        />
        <Button
          type={"button"}
          onClick={(e) =>
            enableDisableAllAcc({ enable: true, platform: "reddit" })
          }
          color={"primary"}
        >
          Enable All
        </Button>
        <Button
          type={"button"}
          onClick={(e) =>
            enableDisableAllAcc({ enable: false, platform: "reddit" })
          }
          color={"secondary"}
        >
          Disable All
        </Button>
      </div>
      <PaginatedTabel
        data={data}
        onUpdateSortFilter={onUpdateSortFilter}
        currentFilter={sortFilter}
        sortableHeader={[
          { label: "Status", key: "isActive", sortable: false },
          { label: "#", key: "", sortable: false },
          { label: "Unique ADP Name", key: "uniqueName", sortable: false },
          { label: "IATAs", key: "iatas", sortable: true },
          { label: "Url", key: "url", sortable: true },
          { label: "Branch Url", key: "branchUrl", sortable: true },
          { label: "Trello Card", key: "trelloCardId", sortable: true },
          { label: "Created at", key: "createdAt", sortable: true },
          { label: "Edit", key: "", sortable: false },
          { label: "Delete", key: "", sortable: false },
        ]}
      />
      {!!deleteId && (
        <AlertDialog
          heading={"Are you sure?"}
          message="You are deleting this account, you might not be able to recover it again?"
          onCancel={onDeleteCancel}
          onAccept={onDelete}
        />
      )}
    </div>
  );
}