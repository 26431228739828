import * as React from 'react'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import {
  IndeterminentProgress,
  ProgressButton,
  SnackBar
} from '../../components'
import { makeStyles, Paper, TextField } from '@material-ui/core'
import { createStyles, Theme } from '@material-ui/core/styles'
import {
  getSetupInfo,
  editSetupInfo,
  EditSetupInfoProps
} from '../../../services/instagram-setup'
import {
  IContextProps,
  DrawerContext
} from '../../context/ui-context/DrawerContext'

const { useState, useContext, useEffect, useRef } = React

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      marginLeft: '300px;'
    },
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexFlow: 'column wrap',
      padding: theme.spacing(2),
      margin: theme.spacing(10)
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40%'
    },
    heading: {
      marginLeft: theme.spacing(1)
    },
    paragraph: {
      marginLeft: theme.spacing(1),
      maxWidth: '34em'
    },
    control: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '20px'
    },
    error: {
      color: 'red',
      padding: '5px'
    }
  })
)

export const InstagramSetup = () => {
  const { setActivePage } = useContext<IContextProps>(DrawerContext)
  setActivePage('instagram-setup', 'Instagram Setup Info')

  const formRef: any = useRef<any>()
  const [formState, setFormState] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [errors, setErrors] = React.useState<any>({})

  const classes = useStyles({})

  function setDataToFormState(data: any) {
    setFormState({
      ...data,
      username: get(data, 'account.username'),
      password: get(data, 'account.password'),
      proxy: get(data, 'account.proxy')
    })
  }

  function loadSetupInfo() {
    setLoading(true)
    getSetupInfo()
      .then(({ data }) => setDataToFormState(data))
      .finally(() => setLoading(false))
  }

  useEffect(loadSetupInfo, [])

  function handleChange(
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) {
    e.preventDefault()
    const newFormState = {
      ...formState,
      [e.currentTarget.name]: e.currentTarget.value
    }

    setFormState(newFormState)
    const errors = validate(newFormState)
    console.log('newFormState ->', newFormState)
    console.log('errors ->', errors)
    setErrors(errors)
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (!isEmpty(errors)) {
      return
    }

    setLoading(true)

    const data = {
      account: {
        username: formState.username,
        password: formState.password,
        proxy: formState.proxy
      },
      queueMinDelayMinutes: formState.queueMinDelayMinutes,
      queueMaxDelayMinutes: formState.queueMaxDelayMinutes,
      queuePauseStartHour: formState.queuePauseStartHour,
      queuePauseEndHour: formState.queuePauseEndHour
    }

    editSetupInfo(data)
      .then(({ data }) => {
        if (data) {
          setTimeout(() => {
            setDataToFormState(data)
            setMessage('Instagram Account saved successfully')
            setShowMessage(true)
            setTimeout(() => {
              setMessage('')
              setShowMessage(false)
            }, 5000)
          }, 1000)
        }
      })
      .catch(error => {
        setMessage('Something went wrong')
        setShowMessage(true)
      })
      .finally(() => {
        // delay this so user can clearly see that it worked
        // otherwise it's too fast
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      })
  }

  return (
    <Paper className={classes.root}>
      <h2 className={classes.heading}>Instagram Setup Info</h2>
      <p className={classes.paragraph}>
        Every income deal will be added to the end of the queue and will posted
        according to the schedule setup below.
      </p>
      <p className={classes.paragraph}>
        When a post fails to be uploaded with a caption, the system will try to
        force-add a new caption, and if that doesn't work either it will delete
        the post and put it back into the end of the queue with a new caption
        and without hashtags. It will try 3 times before rejecting post.
      </p>

      {!!loading && <IndeterminentProgress />}

      <h3 className={classes.heading}>Main Account</h3>

      <form onSubmit={handleSubmit} ref={formRef} noValidate={true}>
        <TextField
          autoComplete="off"
          label="Username"
          className={classes.textField}
          helperText={errors.username || 'Enter IG account username'}
          margin="normal"
          name="username"
          type="text"
          value={get(formState, 'username', '')}
          onChange={handleChange}
          autoFocus
          disabled={loading}
          error={!!errors.username}
          required
        />
        <TextField
          autoComplete="off"
          label="Password"
          className={classes.textField}
          helperText={errors.password || 'Enter IG account password'}
          margin="normal"
          name="password"
          type="text"
          value={get(formState, 'password', '')}
          onChange={handleChange}
          disabled={loading}
          error={!!errors.password}
          required
        />
        <TextField
          autoComplete="off"
          label="Proxy"
          className={classes.textField}
          helperText={
            errors.proxy ||
            'Enter IG account proxy in the following format: http://username:password@url:port'
          }
          margin="normal"
          name="proxy"
          type="text"
          value={get(formState, 'proxy', '')}
          onChange={handleChange}
          disabled={loading}
          error={!!errors.proxy}
          required
        />

        <h3 className={classes.heading}>Queue Interval</h3>
        <h4 className={classes.paragraph}>
          A deal will take a random time between the minutes set here before
          being posted one after the other. Currently it's posted once every{' '}
          {get(formState, 'queueMinDelayMinutes', '-')}-
          {get(formState, 'queueMaxDelayMinutes', '-')} minutes
        </h4>
        <TextField
          autoComplete="off"
          label="Minimum Interval Between posts"
          className={classes.textField}
          helperText={
            errors.queueMinDelayMinutes ||
            'Minimum interval in minutes that a deal should take before being posted'
          }
          margin="normal"
          name="queueMinDelayMinutes"
          type="number"
          value={get(formState, 'queueMinDelayMinutes', '')}
          onChange={handleChange}
          disabled={loading}
          error={!!errors.queueMinDelayMinutes}
          required
        />
        <TextField
          autoComplete="off"
          label="Maximum Interval Between posts"
          className={classes.textField}
          helperText={
            errors.queueMaxDelayMinutes ||
            'Maximum interval in minutes that a deal should take before being posted'
          }
          margin="normal"
          name="queueMaxDelayMinutes"
          type="number"
          value={get(formState, 'queueMaxDelayMinutes', '')}
          onChange={handleChange}
          disabled={loading}
          error={!!errors.queueMaxDelayMinutes}
          required
        />

        <h3 className={classes.heading}>Queue Pause Time (USA Eastern)</h3>
        <h4 className={classes.paragraph}>
          Deals will not be posted at any time between this pause.
        </h4>
        <TextField
          autoComplete="off"
          label="Start Pause Hour"
          className={classes.textField}
          helperText={
            errors.queuePauseStartHour ||
            `Hour (24-hours format) for the pause to begin. Currently at ${getUSTimeFormat(
              get(formState, 'queuePauseStartHour', 0)
            )}`
          }
          margin="normal"
          name="queuePauseStartHour"
          type="number"
          value={get(formState, 'queuePauseStartHour', 0)}
          onChange={handleChange}
          disabled={loading}
          error={!!errors.queuePauseStartHour}
          required
        />
        <TextField
          autoComplete="off"
          label="End Pause Hour"
          className={classes.textField}
          helperText={
            errors.queuePauseEndHour ||
            `Hour (24-hours format) for the pause to end. Currently at ${getUSTimeFormat(
              get(formState, 'queuePauseEndHour', 0)
            )}`
          }
          margin="normal"
          name="queuePauseEndHour"
          type="number"
          value={get(formState, 'queuePauseEndHour', 0)}
          onChange={handleChange}
          disabled={loading}
          error={!!errors.queuePauseEndHour}
          required
        />

        <div className={classes.control}>
          <ProgressButton
            title="Save"
            type="submit"
            loading={loading}
            disabled={loading || !isEmpty(errors)}
          />
        </div>
      </form>

      {!!showMessage && (
        <SnackBar message={message} onClose={() => setShowMessage(false)} />
      )}
    </Paper>
  )
}

// helpers

function getUSTimeFormat(hour: number) {
  return moment()
    .tz('America/New_York')
    .hour(hour)
    .startOf('hour')
    .format('hh:mma')
}

function validate(data: EditSetupInfoProps) {
  let errors: { [k: string]: any } = {}

  // check if anything is empty
  errors = reduce(
    data,
    (_errors: { [k: string]: any }, value, key) => {
      if (typeof value === 'undefined' || value === '') {
        return {
          ..._errors,
          [key]: 'This cannot be empty'
        }
      }
      return _errors
    },
    {}
  )

  // check if min values are smaller than max
  if (Number(data.queueMaxDelayMinutes) < Number(data.queueMinDelayMinutes)) {
    errors.queueMaxDelayMinutes = 'Maximum needs to be bigger than minimum.'
  }

  return errors
}
