import * as React from 'react'
import {
  Home,
  Camera,
  Computer,
  Pages,
  Add,
  ViewList,
  Error as Warning,
  Dashboard,
  Assignment,
  Wallpaper
} from '@material-ui/icons'
import {
  Dashboard as DB,
  RedditListing,
  CreateReddit,
  EditReddit,
  RedditIssues,
  InstagramSetup,
  InstagramListing,
  InstagramIssues,
  CreateInstagram,
  EditInstagram,
  EditTwitter,
  TwitterListing,
  CreateTwitter,
  TwitterIssues,
  CreateDealImage,
  SomePage,
  TrackerDealsPage,
  AutoPosterTemplateTwitter,
  AutoPosterTemplateReddit,
  AutoPosterTemplateFacebook,
  AutoPosterTemplateInstagram,
} from "../../pages/";
import { ManualPost } from '../../pages/manual-work-pages/manual-post-ig/ManualPost'
import { CreateFacebook } from '../../pages/facebook/CreateFacebook'
import { FacebookListing } from '../../pages/facebook/FacebookListing'
import { EditFacebook } from '../../pages/facebook/EditFacebook'
import PostAddIcon from "@material-ui/icons/PostAdd";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import RedditIcon from "@material-ui/icons/Reddit";
import PostNewsIcon from "@material-ui/icons/DynamicFeed";
import { CreatePostNews } from '../../pages/postNews/CreatePostNews';
import { PostNewsListing } from '../../pages/postNews/PostNewsListing';
import { EditPostNews } from '../../pages/postNews/EditPostNews';
import { PostNewsIssues } from '../../pages/postNews/PostNewsIssues';
import { AutoPosterTemplatePostNews } from '../../pages/apTemplates/AutoPosterTemplatePostNews';
import { CreateThreads } from '../../pages/threads/CreateThreads';
import { EditThreads } from '../../pages/threads/EditThreads';
import { ThreadsListing } from '../../pages/threads/ThreadsListing';
import { AutoPosterTemplateThreads } from '../../pages/apTemplates/AutoPosterTemplateThreads';
export const DrawerItems: SideBarItem[] = [
         {
           icon: <Dashboard color={"secondary"} />,
           slug: "dashboard",
           name: "Dashboard",
           route: "/dashboard",
           component: DB,
           children: [],
         },
         {
           icon: <Computer color={"secondary"} />,
           name: "Reddit accounts",
           slug: "reddit",
           route: "/dashboard/reddit",
           children: [
             {
               name: "Issues",
               route: "/dashboard/reddit/issues",
               component: RedditIssues,
               icon: <Warning color={"secondary"} />,
               slug: "reddit-issues",
             },
             {
               name: "Add",
               route: "/dashboard/reddit/new",
               icon: <Add color={"secondary"} />,
               slug: "reddit-add",
               component: CreateReddit,
             },
             {
               name: "View / Manage",
               route: "/dashboard/reddit",
               icon: <ViewList color={"secondary"} />,
               component: RedditListing,
               slug: "reddit-view",
             },
             {
               name: "Edit",
               route: "/dashboard/reddit/:id",
               hidden: true,
               component: EditReddit,
               icon: <Home color={"secondary"} />,
               slug: "reddit-edit",
             },
           ],
         },
         {
           icon: <Camera color={"secondary"} />,
           name: "Instagram accounts",
           slug: "instagram",
           route: "/dashboard/instagram",
           children: [
             {
               name: "Setup Info",
               route: "/dashboard/instagram/setup",
               component: InstagramSetup,
               icon: <Assignment color={"secondary"} />,
               slug: "instagram-setup",
             },
             {
               name: "Issues",
               route: "/dashboard/instagram/issues",
               component: InstagramIssues,
               icon: <Warning color={"secondary"} />,
               slug: "instagram-issues",
             },
             {
               name: "Add",
               route: "/dashboard/instagram/new",
               icon: <Add color={"secondary"} />,
               slug: "instagram-add",
               component: CreateInstagram,
             },
             {
               name: "View / Manage",
               route: "/dashboard/instagram",
               icon: <ViewList color={"secondary"} />,
               component: InstagramListing,
               slug: "instagram-view",
             },
             {
               name: "Edit",
               route: "/dashboard/instagram/:id",
               hidden: true,
               component: EditInstagram,
               icon: <Home color={"secondary"} />,
               slug: "instagram-edit",
             },
           ],
         },
         {
           icon: <Camera color={"secondary"} />,
           name: "PostNews accounts",
           slug: "postNews",
           route: "/dashboard/postNews",
           children: [
             {
               name: "Issues",
               route: "/dashboard/postNews/issues",
               component: PostNewsIssues,
               icon: <Warning color={"secondary"} />,
               slug: "postNews-issues",
             },
             {
               name: "Add",
               route: "/dashboard/postNews/new",
               icon: <Add color={"secondary"} />,
               slug: "postNews-add",
               component: CreatePostNews,
             },
             {
               name: "View / Manage",
               route: "/dashboard/postNews",
               icon: <ViewList color={"secondary"} />,
               component: PostNewsListing,
               slug: "postNews-view",
             },
             {
               name: "Edit",
               route: "/dashboard/postNews/:id",
               hidden: true,
               component: EditPostNews,
               icon: <Home color={"secondary"} />,
               slug: "postNews-edit",
             },
           ],
         },
         {
           icon: <Pages color={"secondary"} />,
           name: "Facebook accounts",
           slug: "facebook",
           route: "/dashboard/facebook",
           children: [
             // {
             //   name: 'Issues',
             //   route: '/dashboard/facebook/issues',
             //   component: TwitterIssues,
             //   icon: <Warning color={'secondary'} />,
             //   slug: 'facebook-issues'
             // },
             {
               name: "Add",
               route: "/dashboard/facebook/new",
               icon: <Add color={"secondary"} />,
               slug: "facebook-add",
               component: CreateFacebook,
             },
             {
               name: "View / Manage",
               route: "/dashboard/facebook",
               icon: <ViewList color={"secondary"} />,
               component: FacebookListing,
               slug: "facebook-view",
             },
             {
               name: "Edit",
               route: "/dashboard/facebook/:id",
               hidden: true,
               component: EditFacebook,
               icon: <Home color={"secondary"} />,
               slug: "facebook-edit",
             },
           ],
         },
         {
           icon: <Pages color={"secondary"} />,
           name: "Threads Accounts",
           slug: "threads",
           route: "/dashboard/threads",
           children: [
             // {
             //   name: 'Issues',
             //   route: '/dashboard/facebook/issues',
             //   component: TwitterIssues,
             //   icon: <Warning color={'secondary'} />,
             //   slug: 'facebook-issues'
             // },
             {
               name: "Add",
               route: "/dashboard/threads/new",
               icon: <Add color={"secondary"} />,
               slug: "threads-add",
               component: CreateThreads,
             },
             {
               name: "View / Manage",
               route: "/dashboard/threads",
               icon: <ViewList color={"secondary"} />,
               component: ThreadsListing,
               slug: "threads-view",
             },
             {
               name: "Edit",
               route: "/dashboard/threads/:id",
               hidden: true,
               component: EditThreads,
               icon: <Home color={"secondary"} />,
               slug: "threads-edit",
             },
           ],
         },
         {
           icon: <Pages color={"secondary"} />,
           name: "Twitter accounts",
           slug: "twitter",
           route: "/dashboard/twitter",
           children: [
             {
               name: "Issues",
               route: "/dashboard/twitter/issues",
               component: TwitterIssues,
               icon: <Warning color={"secondary"} />,
               slug: "twitter-issues",
             },
             {
               name: "Add",
               route: "/dashboard/twitter/new",
               icon: <Add color={"secondary"} />,
               slug: "twitter-add",
               component: CreateTwitter,
             },
             {
               name: "View / Manage",
               route: "/dashboard/twitter",
               icon: <ViewList color={"secondary"} />,
               component: TwitterListing,
               slug: "twitter-view",
             },
             {
               name: "Edit",
               route: "/dashboard/twitter/:id",
               hidden: true,
               component: EditTwitter,
               icon: <Home color={"secondary"} />,
               slug: "twitter-edit",
             },
           ],
         },
         {
           icon: <Pages color={"secondary"} />,
           name: "Manual Instagram Posts",
           slug: "instagram-post",
           route: "/dashboard/instagram",
           children: [
             {
               name: "Manual Instagram Posting",
               route: "/dashboard//i/m/post",
               component: ManualPost,
               icon: <Warning color={"secondary"} />,
               slug: "instagram-issues",
             },
           ],
         },
         {
           icon: <PostAddIcon color={"secondary"} />,
           name: "AP Templates",
           slug: "aptemplates",
           route: "/dashboard/ap-templates",
           children: [
             {
               name: "Twitter",
               route: "/dashboard/ap-templates/twitter",
               component: AutoPosterTemplateTwitter,
               icon: <TwitterIcon color={"secondary"} />,
               slug: "ap-templates-twitter",
             },
             {
               name: "Reddit",
               route: "/dashboard/ap-templates/reddit",
               component: AutoPosterTemplateReddit,
               icon: <RedditIcon color={"secondary"} />,
               slug: "ap-templates-reddit",
             },
             {
               name: "Facebook",
               route: "/dashboard/ap-templates/facebook",
               component: AutoPosterTemplateFacebook,
               icon: <FacebookIcon color={"secondary"} />,
               slug: "ap-templates-twitter",
             },
             {
               name: "Instagram",
               route: "/dashboard/ap-templates/instagram",
               component: AutoPosterTemplateInstagram,
               icon: <InstagramIcon color={"secondary"} />,
               slug: "ap-templates-instagram",
             },
             {
               name: "PostNews",
               route: "/dashboard/ap-templates/postNews",
               component: AutoPosterTemplatePostNews,
               icon: <PostNewsIcon color={"secondary"} />,
               slug: "ap-templates-postNews",
             },
             {
               name: "Threads",
               route: "/dashboard/ap-templates/threads",
               component: AutoPosterTemplateThreads,
               icon: <PostNewsIcon color={"secondary"} />,
               slug: "ap-templates-threads",
             },
           ],
         },
         {
           icon: <Pages color={"secondary"} />,
           name: "Tracker",
           slug: "tracker",
           route: "/dashboard/tracker",
           children: [
             {
               name: "SoMe",
               route: "/dashboard/tracker/some",
               component: SomePage,
               icon: <Warning color={"secondary"} />,
               slug: "tracker-some",
             },
             {
               name: "Deals",
               route: "/dashboard/tracker/deals",
               component: TrackerDealsPage,
               icon: <Warning color={"secondary"} />,
               slug: "tracker-deals",
             },
           ],
         },
         {
           icon: <Wallpaper color={"secondary"} />,
           slug: "dealimage",
           name: "Deal Image",
           route: "/deal-image",
           component: CreateDealImage,
           children: [],
         },
       ];
