import * as React from 'react';
import { saveAs } from 'file-saver'

import {
  getAccountsOnAutoPoster,
  createDailyAccsCSV,
  createNotScrapedAccsCSV,
  createScrapedYesterdayTodayAccsCSV,
  createGrowthLossPercentageAccsCSV
} from '../../../services/some';
import { convertAccsToCsv } from '../../utils/helpers';


interface IProps { }

export const SomePage = (props: IProps) => {

  const getAllSCADPAccounts = async () => {
    let res = await getAccountsOnAutoPoster();
    let csv = convertAccsToCsv(res)
    var encodedUri = encodeURI(csv)
    window.open(encodedUri)
  }

  const createDailyCSV = async () => {
    try {
      let res = await createDailyAccsCSV();
      let csvLinesArr = res.data
      // insert \n after each entry of csv rows array
      console.log('csv response is', res.data);
      let csvLinesArrLineBreaks = [...csvLinesArr].map((e, i) => i < csvLinesArr.length - 1 ? [e, '\n'] : [e]).reduce((a, b) => a.concat(b))
      var blob = new Blob(csvLinesArrLineBreaks, { type: 'text/csv;charset:utf-8' })
      saveAs(blob, "accs-daily-scraped.csv", { autoBom: true })
    }
    catch (err) {
      console.log("some err occured --- err: " + err)
    }
  }

  const createNotScrapedCSV = async () => {
    try {
      let res = await createNotScrapedAccsCSV();
      let csvLinesArr = res.data
      // insert \n after each entry of csv rows array
      let csvLinesArrLineBreaks = [...csvLinesArr].map((e, i) => i < csvLinesArr.length - 1 ? [e, '\n'] : [e]).reduce((a, b) => a.concat(b))
      var blob = new Blob(csvLinesArrLineBreaks, { type: 'text/csv;charset:utf-8' })
      saveAs(blob, "accs-not-scraped.csv", { autoBom: true })
    }
    catch (err) {
      console.log("some err occured --- err: " + err)
    }
  }

  const createScrapedYesterdayTodayCSV = async () => {
    try {
      let res = await createScrapedYesterdayTodayAccsCSV();
      let csvLinesArr = res.data
      // insert \n after each entry of csv rows array
      let csvLinesArrLineBreaks = [...csvLinesArr].map((e, i) => i < csvLinesArr.length - 1 ? [e, '\n'] : [e]).reduce((a, b) => a.concat(b))
      var blob = new Blob(csvLinesArrLineBreaks, { type: 'text/csv;charset:utf-8' })
      saveAs(blob, "accs-yesterday-today.csv", { autoBom: true })
    }
    catch (err) {
      console.log("some err occured --- err: " + err)
    }
  }

  const createGrowthLossCSV = async () => {
    try {
      let res = await createGrowthLossPercentageAccsCSV();
      let csvLinesArr = res.data
      // insert \n after each entry of csv rows array
      let csvLinesArrLineBreaks = [...csvLinesArr].map((e, i) => i < csvLinesArr.length - 1 ? [e, '\n'] : [e]).reduce((a, b) => a.concat(b))
      var blob = new Blob(csvLinesArrLineBreaks, { type: 'text/csv;charset:utf-8' })
      saveAs(blob, "accs-growth-loss.csv", { autoBom: true })
    }
    catch (err) {
      console.log("some err occured --- err: " + err)
    }
  }

  return (<React.Fragment>

    <p>Generate csv seed file to setup inital accounts at SoMe-tracker; use this csv as seed file for adding initial accounts to SoMe-tracker</p>
    <button onClick={getAllSCADPAccounts}>get Auto-poster accounts' csv</button>
    <hr />
    <p>Create <u>scraped daily accounts</u> csv - that shows accounts growth over time</p>
    <button onClick={createDailyCSV}>create daily accs csv</button>
    <hr />
    <p>Create <u>not scraped accounts</u> csv - that lists accounts that were not scraped (doesn't exist/suspended/error/etc)</p>
    <button onClick={createNotScrapedCSV}>create not scraped accs csv</button>
    <hr />
    <p>Create <u>accounts scraped yesterday or today</u> csv - that lists accounts that were 1. scraped yesterday and not today; 2. scraped today and not yesterday</p>
    <button onClick={createScrapedYesterdayTodayCSV}>create yesterday today accs csv</button>
    <hr />
    <p>Create <u>accounts growth loss percentage</u> csv - that lists accounts' growth/loss over last 7 days and last 30 days</p>
    <button onClick={createGrowthLossCSV}>create growth loss percentage accs csv</button>
  </React.Fragment>);
};
