import axios from 'axios'
import {readStorage} from '../../client/utils/helpers'

type Platform = 'reddit' | 'twitter' | 'instagram' | 'facebook' | 'postNews' | 'threads'

interface GetAccountProps {
  platform: Platform
  accid?: string
  lastLimitId?: number;
}

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:8080'
}

export const getAccounts = (args: GetAccountProps) => {
  const {platform, accid, lastLimitId} = args
  return axios.get(
    `/accounts?platform=${platform}${accid ? `&accid=${accid}` : ''}${lastLimitId !== undefined ? `&lastLimitId=${lastLimitId}` : ''}`,
    {auth: readStorage('user', true)}
  )
}

export const getAccountsHealthReport = (platform: string) => {
  // const {platform, accid, lastLimitId} = args
  return axios.get(
    `/accounts/healthcheck?platform=${platform}`,
    {auth: readStorage('user', true)}
  )
}
export const getSomeTrackablityReport = (platform: string) => {
  // const {platform, accid, lastLimitId} = args
  return axios.get(
    `/accounts/someTrackabilityChecker?platform=${platform}`,
    {auth: readStorage('user', true)}
  )
}
export const getAccountsHealthReportSingle = (platform: string, accid: string) => {
  // const {platform, accid, lastLimitId} = args
  return axios.get(
    `/accounts/healthcheckSingle?platform=${platform}&accid=${accid}`,
    {auth: readStorage('user', true)}
  )
}

export const getAccountsManual = (args: GetAccountProps) => {
  const {platform, accid, lastLimitId} = args
  return axios.get(
    `/accounts/manual?platform=${platform}${accid ? `&accid=${accid}` : ''}${lastLimitId !== undefined ? `&lastLimitId=${lastLimitId}` : ''}`,
    {auth: readStorage('user', true)}
  )
}

interface PostAccountProps {
  iatas: string[]
  url?: string | URL
  platform: Platform
}

export const createAccount = (args: PostAccountProps) => {
  return axios.post('/accounts', args, {auth: readStorage('user', true)})
}

interface EditAccountProps extends PostAccountProps {
  id: string
}

export const editAccount = (args: EditAccountProps) => {
  return axios.post('/account/edit', args, {auth: readStorage('user', true)})
}

interface DeleteAccountProps {
  platform: Platform
  id: string
}

export const deleteAccount = (args: DeleteAccountProps) => {
  return axios.delete('/account/delete', {
    params: args,
    auth: readStorage('user', true)
  })
}

export const donwloadCsv = (args: { platform: any }) => {
  return axios.get(`/accounts/csv?platform=${args.platform}`, {
    auth: readStorage('user', true)
  })
}

interface CleanupAccountProps {
  platform: 'instagram' | 'twitter'
  id: string
  internalName?: string
  iata?: string
}

export const cleanAccount = (args: CleanupAccountProps) => {
  return axios.post(`/account/clean`, args, {
    auth: readStorage('user', true)
  })
}

export const updateManualClean = (args: {cleaned: boolean, id: string}) => {
  return axios.post(`/account/manual/clean`, args, {
    auth: readStorage('user', true)
  })
}

export const enableDisableAll = (args: { platform: AccountType, enable: boolean }) => {
  return axios.post('/accounts/enable/all', args, {
    auth: readStorage('user', true)
  });
}

export const downloadAccountsBackup = () => {
  return axios.get('/instagram/backup', {auth: readStorage('user', true)})
}
