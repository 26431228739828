import * as React from 'react';
import {Collapse, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useState} from "react";
import {uniqueId} from "../../utils/helpers";

interface IProps {
  item: SideBarItem;
  onRouteChange: any;
  activePage: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
  }),
);
export const CollapsableMenu = (props: IProps) => {
  const {item} = props;
  const classes = useStyles();
  const [state, setState] = useState<boolean>(props.activePage.includes(item.slug));
  const toggle = () => {
    setState(!state);
  };

  return (
    <>
      <ListItem
        button
        onClick={toggle}
        key={uniqueId()}
      >
        <ListItemIcon>
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.name}/>
        {state ? <ExpandLess/> : <ExpandMore/>}
      </ListItem>
      <Collapse in={state} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {!!(item.children && item.children.length) &&
          item.children.map((child: SiderBarItemChild, index) => {
              if (!child.hidden) {
                return (
                  <ListItem
                    key={uniqueId()}
                    onClick={() => props.onRouteChange(child.route)}
                    className={classes.nested}
                    selected={child.slug === props.activePage}
                    button
                  >
                    <ListItemIcon>
                      {child.icon}
                    </ListItemIcon>
                    <ListItemText primary={child.name}/>
                  </ListItem>
                );
              } else {
                return null
              }
            }
          )
          }
        </List>
      </Collapse>
    </>
  );
};