import { toast } from "react-toastify";

export const parseEmailTemplate = (str, obj) => {
  return str.replace(/{{\s*([\w\.]+)\s*}}/g, function(tag, match) {
    var nodes = match.split("."),
      current = obj,
      // eslint-disable-next-line prefer-destructuring
      length = nodes.length,
      i = 0;
    while (i < length) {
      try {
        current = current[nodes[i]];
        if(!current) {
          toast.error('Unknown Variable Used');
        }
      } catch (e) {
        return "";
      }
      i++;
    }
    return current;
  });
};
