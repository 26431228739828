import * as React from "react";

import { toast } from "react-toastify";
import { convertAccsToCsv, dateFormat, uniqueId } from "../../utils/helpers";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import { PaginatedTabel, AlertDialog } from "../../components";
import {
  createStyles,
  Theme,
  Avatar,
  makeStyles,
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Fab,
  Switch,
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";

import SaveIcon from "@material-ui/icons/Save";
import { parseEmailTemplate } from "../../../libs/utils";
import { createOrUpdateByPlatform, getTemplateByPlatform } from "../../../services";
import {
  DrawerContext,
  IContextProps,
} from "../../context/ui-context/DrawerContext";
interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100vh",
      marginTop: theme.spacing(3),
      overflowX: "auto",
      flexGrow: 1,
    },
    card: {
      marginTop: 10,
      marginBottom: 10,
      paddingBottom: 0,
    },
    cardContent: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    gridOuter: {
      flex: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    table: {
      minWidth: 650,
    },
    blackText: {
      color: "#000",
    },
    sampleFields: {
      color: "green",
      // paddingLeft: 10,
      fontWeight: "bold",
    },
    preserveBreak: {
      whiteSpace: "pre-line",
    },
    chip: {
      margin: theme.spacing(1),
    },
    cleanbtn: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-end",
      marginBottom: "15px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "40%",
    },
    head: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
    },
    appBar: {
      position: "relative",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    pointer: {
      cursor: "pointer",
    },
    templateTextField: {
      width: "100%",
      minHeight: 25,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    fab: {
      margin: 0,
      top: "auto",
      right: 50,
      bottom: 50,
      left: "auto",
      position: "fixed",
    },
  })
);
const usableTempalteVars = [
  {
    name: "Airline",
    slug: "airline",
    sample: "American",
  },
  {
    name: "Origin City",
    slug: "originCity",
    sample: "New York City",
  },
  {
    name: "Origin IATA",
    slug: "origin",
    sample: "JFK",
  },
  {
    name: "Destination City",
    slug: "destinationCity",
    sample: "Mexico City",
  },
  {
    name: "Destination IATA",
    slug: "destination",
    sample: "MEX",
  },
  {
    name: "Formatted Price",
    slug: "priceWithComma",
    sample: "$209 (USD)",
  },
  {
    name: "Dates Count",
    slug: "datesCount",
    sample: "400",
  },
  {
    name: "Link Format",
    slug: "[name](LINK_URL)",
    notVar: true,
    // sample: "",
  },
  {
    name: "Bold Text",
    slug: "**Text**",
    notVar: true,
    // sample: "",
  },
  {
    name: "List Item",
    slug: "- Item",
    notVar: true,
    // sample: "",
  },
  // {
  //   name: "Branch Link",
  //   slug: "branchLink",
  //   sample: "https://adventuremachine.app.link/9dkwM9ksa5",
  // },
];
// const formatSampleLines = 
export const AutoPosterTemplateReddit = (props: IProps) => {
  const { setActivePage } = React.useContext<IContextProps>(DrawerContext);
  setActivePage("ap-templates-reddit", "Reddit Template");
  const classes = useStyles();
  const [trackerDeals, setTrackerDeals] = React.useState([]);
  const [templateValue, setTemplateValue] = React.useState("");
  const [replyTemplateValue, setReplyTemplateValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [sampleOutput, setSampleOutput] = React.useState("");
  const [rawTrackerDeals, setRawTrackerDeals] = React.useState<any[]>([]);
  const [jsonData, setJsonData] = React.useState<object | null>(null);
  const [sampleMode, setSampleMode] = React.useState(false);
  const onCopyText = (s: any) => {
    toast.success(`Copied ${s}`);
  };
  const handleTemplatechange = (event:any) => {
    setTemplateValue(event.target.value);
  };
  const handleReplyTemplateChange = (event: any) => {
    setReplyTemplateValue(event.target.value);
  };
  const fetchTemplate = async () => {
    const { data } : any = await getTemplateByPlatform("reddit");
    if (data && !data.error) {
      setIsLoading(false);
      if (
        data.response &&
        data.response.templates &&
        data.response.templates.templateValue
      ) {
        setTemplateValue(data.response.templates.templateValue);
      }
      if (
        data.response &&
        data.response.templates &&
        data.response.templates.replyTemplateValue
      ) {
        setReplyTemplateValue(data.response.templates.replyTemplateValue);
      }
    } else {
      toast.error(
        "Failed to fetch existing template. Preventing unwanted overwrites"
      );
    }
    console.log(" x is", data);
    // formatTrackerDeals();
  };
  const saveTemplate = async () => {
    if (
      !templateValue ||
      templateValue.length === 0 ||
      templateValue.trim().length === 0
    ) {
      toast.error("Ooops!!, cannot save empty template");
      return;
    }
    if (
      !replyTemplateValue ||
      replyTemplateValue.length === 0 ||
      replyTemplateValue.trim().length === 0
    ) {
      toast.error("Ooops!!, cannot save empty reply template");
      return;
    }
    setIsLoading(true);
    const { data }: any = await createOrUpdateByPlatform("reddit", {
      templates: {
        templateValue,
        replyTemplateValue,
      },
    });
    if(data && data.response) {
      toast.success('Saved');
    } else {
      toast.error((data && data.error) || "Failed to save");
    }
    setIsLoading(false);
    console.log("ako data is", data);
  };
  React.useEffect(() => {
    const sampleObj:any = {};
    usableTempalteVars.forEach(s => {
      if(!s.notVar) {
        sampleObj[s.slug] = s.sample;
      }
    });
    const templateSampleOutput = parseEmailTemplate(templateValue , sampleObj);
    const replySampleOutput = parseEmailTemplate(replyTemplateValue , sampleObj);
    setSampleOutput(`**Post:**
${templateSampleOutput}
\n
**Reply:**
${replySampleOutput}
    `);
    
  }, [templateValue, replyTemplateValue]);
   React.useEffect(() => {
     fetchTemplate();
   }, []);
  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.gridOuter}>
        <Grid item xs={8} style={{ height: "100%" }}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Title Template
          </Typography>
          <Paper className={classes.paper}>
            <TextField
              id="outlined-multiline-flexible"
              label="Enter Title Template..."
              multiline
              className={classes.templateTextField}
              // maxRows={4}
              rows={10}
              value={templateValue}
              onChange={handleTemplatechange}
              variant="outlined"
            />
          </Paper>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Reply Template
          </Typography>
          <Paper className={classes.paper}>
            <TextField
              id="outlined-multiline-flexible"
              label="Enter Reply Template..."
              multiline
              className={classes.templateTextField}
              // maxRows={4}
              rows={10}
              value={replyTemplateValue}
              onChange={handleReplyTemplateChange}
              variant="outlined"
            />
          </Paper>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Debug Mode
            <Switch
              checked={sampleMode}
              onChange={(event) => {
                setSampleMode(event.target.checked);
              }}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Typography>
          {!!sampleMode && (
            <div>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Sample
              </Typography>
              <ReactMarkdown>{sampleOutput}</ReactMarkdown>
              {/* <NewLineToBr>{sampleOutput}</NewLineToBr> */}
              {/* <span className={classes.preserveBreak}></span> */}
            </div>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Variables
          </Typography>

          {usableTempalteVars &&
            usableTempalteVars.map((row) => (
              <Card className={classes.card} variant="outlined" key={row.slug}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6" component="h6">
                    <span>{row.name}</span>
                    <Button size="small">
                      <CopyToClipboard
                        text={row.notVar ? row.slug : `{{${row.slug}}}`}
                        onCopy={onCopyText}
                      >
                        <Avatar
                          className={classes.pointer}
                          style={{
                            height: 25,
                            width: 25,
                            backgroundColor: "#000",
                          }}
                        >
                          <FileCopyIcon style={{ height: 15, width: 15 }} />
                        </Avatar>
                      </CopyToClipboard>
                    </Button>
                  </Typography>

                  <Typography className={classes.pos} color="textSecondary">
                    {row.slug}
                  </Typography>
                  {!!sampleMode && (
                    <Typography className={classes.sampleFields}>
                      Sample: {row.sample}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            ))}
        </Grid>
      </Grid>
      <Fab
        variant="extended"
        // size="small"
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={saveTemplate}
      >
        <SaveIcon className={classes.extendedIcon} />
        Save
      </Fab>
    </div>
  );
};
