import React from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {green} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

interface IProps {
  loading: boolean;
  title: string;
  disabled?: boolean;
  success?: boolean;
  type?: 'submit' | 'button';

  onClick?(): void;
}

export const ProgressButton = (props: IProps) => {
  const classes = useStyles();
  const timer = React.useRef<any>();
  const {loading, onClick, title, disabled, type} = props;

  const buttonClassname = clsx({
    [classes.buttonSuccess]: false,
  });

  React.useEffect(() => {
    return () => {
      // eslint-disable-next-line
      clearTimeout(timer.current);
    };
  }, []);

  function handleButtonClick() {
    if (onClick) onClick();
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          type={type ? type : 'button'}
          className={buttonClassname}
          disabled={loading || disabled}
          onClick={handleButtonClick}
        >
          {title}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
      </div>
    </div>
  );
}