import * as React from "react";
import { saveAs } from "file-saver";

import {
  //   getAccountsOnAutoPoster,
  //   createDailyAccsCSV,
  //   createNotScrapedAccsCSV,
  //   createScrapedYesterdayTodayAccsCSV,
  //   createGrowthLossPercentageAccsCSV
  deleteTracker,
  resolveTracker,
  getAllDealsTracker,
  restartTrackerPlatform,
} from "../../../services/some";
import { toast } from "react-toastify";
import { convertAccsToCsv, dateFormat, uniqueId } from "../../utils/helpers";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import { PaginatedTabel, AlertDialog } from "../../components";
import {
  createStyles,
  Link,
  Theme,
  Avatar,
  makeStyles,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Chip,
  Tooltip
} from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import CheckCircle from "@material-ui/icons/Done";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import RedditIcon from "@material-ui/icons/Reddit";
import ReplayIcon from "@material-ui/icons/Replay";
import { orderBy } from "lodash";
interface IProps {}
const platformShorts: any = {
  "instagram" : "IG",
  "facebook" : "FB",
  "twitter" : "TW",
  "reddit" : "RD",
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    chip: {
      margin: theme.spacing(1),
    },
    pointer: {
      cursor: "pointer",
    },
    formControl: {
      minWidth: 300,
    },
    flux: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    cleanbtn: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-end",
      marginBottom: "15px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "40%",
    },
    head: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    listWrap: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);
const TrackerAccountTags = (props: any) => {
  return (
    <React.Fragment>
      {props.account && (
        <Chip
          label={`Attempts: ${
            props.account.results ? props.account.results.length : 0
          }`}
          style={{ margin: 2 }}
          size="small"
          color="primary"
          variant="outlined"
        />
      )}
      {props.account && !!props.account.completed && (
        <Chip
          label="Completed"
          style={{
            margin: 2,
            backgroundColor: props.account.verified ? 'green' : 'red',
            color: "#fff",
          }}
          size="small"
          color="primary"
          variant="outlined"
        />
      )}
      {props.account && !!props.account.verified && (
        <Chip
          label="Verified"
          size="small"
          style={{ margin: 2, backgroundColor: "green", color: "#fff" }}
          color="primary"
          variant="outlined"
        />
      )}
    </React.Fragment>
  );
}
export const TrackerDealsPage = (props: IProps) => {
  const classes = useStyles();
  const [trackerDeals, setTrackerDeals] = React.useState([]);
  const [rawTrackerDeals, setRawTrackerDeals] = React.useState<any[]>([]);
  const [jsonData, setJsonData] = React.useState<object | null>(null);
  const [deleteId, setDeleteId] = React.useState<string | number | null>("");
  const [restartTrackerObj, setRestartTrackerObj] = React.useState<any>(null);
  const [resolveTrackerId, setResolveTrackerId] = React.useState<string | number | null>("");
  const [sortFilter, setSortFilter] = React.useState<{ key: string; direction: "asc" | "desc" }>();
  const onCopyText = (s: any) => {
    toast.success(`Copied ${s}`);
  };
  const onTrackerResetCancel = () => {
    setRestartTrackerObj(null);
  };
  const onDeleteCancel = () => {
    setDeleteId(null)
  }
  const onTrackerReset = async () => {
    const param = restartTrackerObj;
    setRestartTrackerObj(null);
    // removeAccount('instagram', id)
    // await restartTrackerObj();
    // restartTrackerPlatform;
    const { data } = await restartTrackerPlatform(restartTrackerObj);
    console.log('data is ====', data);
    if(data && data.response) {
      toast.success(`Success`);
      fetchTrackerDeals();
    } else {
      toast.error((data && data.error) || 'Failed Resetting tracker platform');
    }
  };
  const onResolveTrackerCancel = () => {
    setResolveTrackerId(null);
  }
  const onJsonCancel = () => {
    setJsonData(null);
  };
  const onUpdateSortFilter = (filter: {
    key: string;
    direction: "asc" | "desc";
  }) => {
    setSortFilter(filter);
  }
  const onDelete = async () => {
    const id = deleteId
    setDeleteId(null)
    // removeAccount('instagram', id)
    await deleteTracker(id);
    fetchTrackerDeals();
  }
  const onResolve = async () => {
    const id = resolveTrackerId;
    setResolveTrackerId(null);
    // removeAccount('instagram', id)
    await resolveTracker(id);
    fetchTrackerDeals();
  };
  React.useEffect(()=>{
    console.log("Sotring is", sortFilter);
    // orderBy;
    if(sortFilter) {
      let _rawTrackerDeals = { ...rawTrackerDeals };
      _rawTrackerDeals = orderBy(
        _rawTrackerDeals,
        [sortFilter.key],
        [sortFilter.direction]
      );
      console.log("ordered is", _rawTrackerDeals);
      setRawTrackerDeals(_rawTrackerDeals);
    }
  }, [sortFilter]);
  const formatTrackerDeals = async () => {
    const _trackerDeals: any = [];
     if (rawTrackerDeals) {
       rawTrackerDeals.forEach((row: any) => {
         console.log("hahah");
         const instagram = row.instagram && !!row.instagram.completed;
         const instagramVerified = row.instagram && !!row.instagram.verified;
         const twitter = row.twitter && !!row.twitter.completed;
         const twitterVerified = row.twitter && !!row.twitter.verified;
         const facebook = row.facebook && !!row.facebook.completed;
         const facebookVerified = row.facebook && !!row.facebook.verified;
         const reddit = row.reddit && !!row.reddit.completed;
         const redditVerified = row.reddit && !!row.reddit.verified;
         let newDetailStatus = (
           <React.Fragment>
             {row.status || "NEW"}
             {row &&
               row.accounts &&
               row.accounts.map((singleAccount: any) => (
                 <React.Fragment key={`${singleAccount.identifier}-${singleAccount.platform}`}>
                   <Chip
                     avatar={
                       <React.Fragment>
                         <Avatar
                           style={{
                             height: 25,
                             width: 25,
                             margin: 5,
                             fontSize: 15,
                             backgroundColor:
                               singleAccount && singleAccount.completed
                                 ? singleAccount.verified
                                   ? "green"
                                   : "red"
                                 : "#455a64",
                           }}
                         >
                           {singleAccount && singleAccount.verified ? "✓" : "x"}
                         </Avatar>
                         {!!(
                           singleAccount &&
                           singleAccount.post &&
                           singleAccount.post.post_url
                         ) && (
                           <CopyToClipboard
                             text={singleAccount.post.post_url || "No data"}
                             onCopy={onCopyText}
                           >
                             <Avatar
                               style={{
                                 height: 25,
                                 width: 25,
                                 backgroundColor: "#000",
                               }}
                             >
                               <FileCopyIcon
                                 style={{ height: 15, width: 15 }}
                               />
                             </Avatar>
                           </CopyToClipboard>
                         )}
                       </React.Fragment>
                     }
                     // label={`${platformShorts[singleAccount.platform] ||
                     //   singleAccount.platform} \n (${singleAccount.identifier})`}
                     label={
                       <div>
                         <strong>FB</strong>
                         <br />
                         <span>sdf</span>
                         <br />
                         <span>sdf</span>
                       </div>
                     }
                     clickable
                     color="primary"
                     onDelete={() => {
                       setJsonData(singleAccount);
                     }}
                     deleteIcon={<VisibilityIcon />}
                     variant="outlined"
                   />
                 </React.Fragment>
               ))}
           </React.Fragment>
         );
         newDetailStatus = (
           <div className={classes.listWrap}>
             <strong>{row.status || "NEW"}</strong>
             <List dense={true}>
               {row &&
                 row.accounts &&
                 row.accounts.map((singleAccount: any) => (
                   <ListItem>
                     <ListItemAvatar>
                       <Avatar
                         style={{
                           // height: 25,
                           // width: 25,
                           margin: 5,
                           fontSize: 15,
                           backgroundColor:
                             singleAccount && singleAccount.completed
                               ? singleAccount.verified
                                 ? "green"
                                 : "red"
                               : "#455a64",
                         }}
                       >
                         {singleAccount.platform === "facebook" && (
                           <FacebookIcon />
                         )}
                         {singleAccount.platform === "instagram" && (
                           <InstagramIcon />
                         )}
                         {singleAccount.platform === "twitter" && (
                           <TwitterIcon />
                         )}
                         {singleAccount.platform === "reddit" && <RedditIcon />}
                       </Avatar>
                     </ListItemAvatar>
                     <ListItemText
                       primary={singleAccount.identifier}
                       secondary={
                         <TrackerAccountTags account={singleAccount} />
                       }
                     />
                     <ListItemSecondaryAction>
                       {!!(
                         singleAccount &&
                         singleAccount.post &&
                         singleAccount.post.post_url
                       ) && (
                         <CopyToClipboard
                           text={singleAccount.post.post_url || "No data"}
                           onCopy={onCopyText}
                         >
                           <IconButton edge="end" aria-label="delete">
                             <FileCopyIcon />
                           </IconButton>
                         </CopyToClipboard>
                       )}

                       <IconButton
                         edge="end"
                         aria-label="delete"
                         onClick={() => setJsonData(singleAccount)}
                       >
                         <VisibilityIcon />
                       </IconButton>
                       {(singleAccount.platform === "instagram" ||
                         singleAccount.platform === "facebook") &&
                         (singleAccount.completed || row.status === 'COMPLETED') &&
                         !singleAccount.verified && (
                           <Tooltip
                             title="Mark to track again"
                             aria-label="Mark to track again"
                           >
                             <IconButton
                               edge="end"
                               aria-label="delete"
                               onClick={() => setRestartTrackerObj({
                                 trackerId: row.id,
                                 account: singleAccount
                               })}
                             >
                               <ReplayIcon />
                             </IconButton>
                           </Tooltip>
                         )}
                     </ListItemSecondaryAction>
                   </ListItem>
                 ))}
             </List>
           </div>
         );
         _trackerDeals.push([
           row.id,
           `${row.sc_origin} -> ${row.sc_destination}`,
           newDetailStatus,
           row.sc_dealid,
           row.count || 0,
           dateFormat(row.createdat),
           dateFormat(row.updatedat),
           <React.Fragment>
             <VisibilityIcon
               key={uniqueId()}
               className={classes.pointer}
               color={"primary"}
               onClick={() => setJsonData(row)}
             />
             <Delete
               key={uniqueId()}
               className={classes.pointer}
               color={"primary"}
               onClick={() => setDeleteId(row.id)}
             />
             <CheckCircle
               key={uniqueId()}
               className={classes.pointer}
               color={"primary"}
               onClick={() => setResolveTrackerId(row.id)}
             />
           </React.Fragment>,
         ]);
        
       });
       setTrackerDeals(_trackerDeals);
     }
  }
  const fetchTrackerDeals = async () => {
    const { data } = await getAllDealsTracker();
    console.log(" x is", data);
    // formatTrackerDeals();
    setRawTrackerDeals(
      data && data.response ? data.response : []
    );
  };

  React.useEffect(() => {
    fetchTrackerDeals();
  }, []);
   React.useEffect(() => {
     formatTrackerDeals();
   }, [rawTrackerDeals]);

  return (
    <React.Fragment>
      <PaginatedTabel
        data={trackerDeals}
        onUpdateSortFilter={onUpdateSortFilter}
        currentFilter={sortFilter}
        sortableHeader={[
          { label: "#", key: "id", sortable: false },
          { label: "IC", key: "title", sortable: true },
          { label: "Status", key: "status", sortable: true },
          { label: "SC ID", key: "sc_dealid", sortable: true },
          { label: "Total Attempts", key: "count", sortable: true },
          { label: "Created at", key: "createdat", sortable: true },
          { label: "Updated at", key: "updatedat", sortable: true },
          { label: "Action", key: "", sortable: false },
          // { label: "Delete", key: "", sortable: false },
        ]}
      />
      {!!jsonData && (
        <AlertDialog
          heading={"Data"}
          isJsonViewer={true}
          json={jsonData}
          onCancel={onJsonCancel}
          noButton={"Close"}
        />
      )}
      {!!deleteId && (
        <AlertDialog
          heading={"Are you sure?"}
          message="You are deleting this tracker, you might not be able to recover it again?"
          onCancel={onDeleteCancel}
          onAccept={onDelete}
        />
      )}
      {!!resolveTrackerId && (
        <AlertDialog
          heading={"Are you sure?"}
          message="You are marking the tracker as complete. SoMe Tracker wont track it further"
          onCancel={onResolveTrackerCancel}
          onAccept={onResolve}
        />
      )}
      {!!restartTrackerObj && (
        <AlertDialog
          heading={"Are you sure?"}
          message="You are marking the tracker for this platform to track again"
          onCancel={onTrackerResetCancel}
          onAccept={onTrackerReset}
        />
      )}
    </React.Fragment>
  );
};
