import axios from 'axios'
import {readStorage} from '../../client/utils/helpers'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:8080'
}

export const getInstagramPendingDeals = () => {
  return axios.get('/instagram/deals', {auth: readStorage('user', true)}
  )
}

export const updatedInstaDealStatus = ({adpName, iatas, deal_id}: { adpName: any, iatas: any, deal_id: any }) => {
  return axios.post('/instagram/deal/update' , {adpName, iatas, deal_id})
}
