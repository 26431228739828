import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { getAccountsHealthReportSingle } from "../../../services/account";
import { Button, Tooltip } from "@material-ui/core";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});
interface IProps {
  editOnApd: any;
  accounts: any;
//   onClose(account?: InstagramAccounts): void;
}

interface IRowProps {
  row: any;
  editOnApd: any;
//   onClose(account?: InstagramAccounts): void;
}
function Row(props: IRowProps) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
    console.log('row is', row);
  const [singleCheckResponse, setSingleCheckResponse] = React.useState<any>(null);
  const getHealthCheckSingle = async () => {
    getAccountsHealthReportSingle("instagram", row.id)
      .then(({ data }) => {
        console.log("data ayo====", data);
        setSingleCheckResponse(data);
      })
      .catch((error) => {
        console.log(error);
        setSingleCheckResponse({
            success: false,
            reason: error.toString(),
        })
      });
  }
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell component="th" scope="row">
          {row.adpName}
        </TableCell> */}
        <TableCell align="left">{row.username}</TableCell>
        <TableCell align="left">{row.apiUsername}</TableCell>
        <TableCell align="left">
          <a href={`https://facebook.com/${row.pageId}`} target="_blank">
            {row.pageId}
          </a>
        </TableCell>
        <TableCell align="left">{row.reason}</TableCell>
        <TableCell align="left">
          <Button
            onClick={() => {
              props.editOnApd(row);
            }}
          >
            Edit on APD
          </Button>
          <Button onClick={getHealthCheckSingle}>Recheck</Button>
          <br />
          {singleCheckResponse && singleCheckResponse.postable && (
            <strong>Recheck Response: Account is now postable</strong>
          )}
          {singleCheckResponse && !!singleCheckResponse.reason && (
            <Tooltip
              title={JSON.stringify(
                singleCheckResponse.responses || {},
                null,
                2
              )}
            >
              <strong>Recheck Response: {singleCheckResponse.reason}</strong>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Responses
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>FB Account</TableCell>
                    <TableCell>Response</TableCell>
                    {/* <TableCell align="left">Amount</TableCell> */}
                    {/* <TableCell align="left">Total price ($)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(
                    (singleCheckResponse && singleCheckResponse.responses) ||
                    row.responses
                  ).map((aRes: any, i: any) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {aRes.account}
                      </TableCell>
                      <TableCell>
                        {(
                          (aRes.response && aRes.response.error) ||
                          JSON.stringify(aRes)
                        ).includes("This endpoint requires the 'pages_read_e")
                          ? `Account doesnot have adequate role in page ===== ${(
                              (aRes.response && aRes.response.error) ||
                              JSON.stringify(aRes)
                            )}`
                          : (aRes.response && aRes.response.error) ||
                            JSON.stringify(aRes)}
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* <div>ramramram</div> */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function MismatchedAccounts(props: IProps) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>APD Username</TableCell>
            <TableCell>Api Username(mapped from pageId)</TableCell>
            <TableCell align="left">Page Id</TableCell>
            <TableCell align="left">Reason&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.accounts.map((row: any) => (
            <Row key={row.name} row={row} editOnApd={props.editOnApd} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
