import * as React from 'react';
import { ProgressButton, SnackBar, TagsInput } from "../../components";
import { makeStyles, Paper, TextField } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { toast } from "react-toastify"

import { editProfileURL } from '../../../services/some';
import { ChipData } from "../../components/tags-input/TagsInput";
import { editAccount } from '../../../services/account';
import { validURL } from '../../utils/helpers'
import { RouteComponentProps, withRouter } from "react-router";
import history from '../../utils/history'
import { AccountsContext, IContextProps } from "../../context/account/AccountContext";
import { DrawerContext, IContextProps as DrawerContextProps } from "../../context/ui-context/DrawerContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexFlow: 'column wrap',
      padding: theme.spacing(0.5),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '40%',
    },
    heading: {
      marginLeft: theme.spacing(1),
    },
    control: {
      display: 'flex',
      justifyContent: 'center',
    },
    progress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }),
);

interface IProps extends RouteComponentProps {
}

const EditC = (props: IProps) => {
  const { match: { params } } = props;
  const classes = useStyles();
  const pars: any = params;
  if (!pars.id) {
    history.push('/');
  }

  const { setActivePage } = React.useContext<DrawerContextProps>(DrawerContext);
  setActivePage('reddit-edit', 'Edit Reddit account');

  const { fetchSingleAccount, singleAccount } = React.useContext<IContextProps>(AccountsContext);
  const defaultTags: ChipData[] = [];
  let defaultUrl: string = '';


  const [state, setState] = React.useState<{ tags: ChipData[], url: string, branchUrl: string, uniqueName: string, trelloCardId: string, refreshToken: string }>({
    tags: [],
    url: '',
    branchUrl: '',
    uniqueName: '',
    trelloCardId: '',
    refreshToken: '',
  });
  const [tagsError, setTagsError] = React.useState<boolean>(false);
  const [urlError, setUrlError] = React.useState<boolean>(false);
  const [branchUrlError, setBranchUrlError] = React.useState<boolean>(false);
  const [uniqueNameError, setUniqueNameError] = React.useState<boolean>(false);
  const [saveLoading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [showMessage, setShowMessage] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!singleAccount) fetchSingleAccount('reddit', pars.id);
    if (singleAccount) {
      singleAccount.iatas.map((item: string, index: number) => {
        defaultTags.push({ key: index, label: item });
        return null;
      });
      // eslint-disable-next-line
      defaultUrl = singleAccount.url;
      setState({
        tags: defaultTags,
        url: defaultUrl,
        branchUrl: singleAccount.branchUrl,
        uniqueName: singleAccount.uniqueName,
        trelloCardId: singleAccount.trelloCardId,
        refreshToken: singleAccount.refreshToken,
      });
    }
  }, [singleAccount]);

  const validate = () => {
    let isValid: boolean = true;
    if (!state.tags.length) {
      isValid = false;
      setTagsError(true);
    } else {
      setTagsError(false);
    }
    if (!state.url) {
      isValid = false;
      setUrlError(true);
    } else {
      if (validURL(state.url)) {
        setUrlError(false);
      } else {
        isValid = false;
        setUrlError(true);
      }
    }

    if (!state.branchUrl) {
      isValid = false;
      setBranchUrlError(true);
    } else {
      setBranchUrlError(false);
    }

    if (!state.uniqueName) {
      isValid = false;
      setUniqueNameError(true);
    } else {
      setUniqueNameError(false);
    }
    return isValid;
  };

  const onChange = (e: any) => {
    setState({ ...state, url: e.target.value });
    validate()
  };
  const onChangeBranch = (e: any) => {
    setState({ ...state, branchUrl: e.target.value });
    validate()
  };
  const onRefreshTokenChange = (e: any) => {
    setState({ ...state, refreshToken: e.target.value });
    validate();
  };
  const onTrelloIdChange = (e: any) => {
    setState({ ...state, trelloCardId: e.target.value });
    validate()
  };
  const onChangeUniqueName = (e: any) => {
    setState({ ...state, uniqueName: e.target.value });
    validate()
  };

  const onChangeTags = (tags: ChipData[]) => {
    setState({ ...state, tags });
    validate();
  };

  const editUrlOnSomeTracker = () => {
    const oldUrl: string = singleAccount.url;
    const newUrl: string = state.url;
    editProfileURL(oldUrl, newUrl)
      .then(({ status }: { status: any }) => {
        if (status === 200) {
          toast.success('Updated reddit profile url on SoMe-tracker')
        }
      })
      .catch((error: any) => {
        console.log(error)
        toast.error('Could not update reddit profile url on SoMe-tracker')
      })
  }

  const onSubmit = () => {
    if (validate()) {
      setLoading(true);
      editUrlOnSomeTracker();
      const tags: string[] = [];
      state.tags.map(tag => tags.push(tag.label));
      editAccount({ ...state, platform: 'reddit', iatas: tags, url: state.url, id: pars.id })
        .then(({ status }) => {
          if (status) {
            setMessage('Account updated successfully')
            setShowMessage(true);
          }
        })
        .catch((error) => {
          setMessage('Something went wrong');
          setShowMessage(true);
        })
        .finally(() => setLoading(false))
    }
  };

  return (
    <Paper className={classes.root}>
      <h3 className={classes.heading}>Add Reddit account</h3>
      <TagsInput onChange={onChangeTags} error={tagsError} tags={state.tags} />
      <TextField
        label="Unique ADP Name"
        id="margin-normal"
        className={classes.textField}
        helperText="Enter unique ADP name"
        margin="normal"
        onChange={onChangeUniqueName}
        value={state.uniqueName}
        autoFocus={true}
        error={uniqueNameError}
        disabled={saveLoading}
      />
      <TextField
        label="URL"
        id="margin-normal"
        className={classes.textField}
        helperText="Enter url"
        margin="normal"
        onChange={onChange}
        value={state.url}
        autoFocus={true}
        error={urlError}
        disabled={saveLoading}
      />
      <TextField
        label="Branch Url"
        id="margin-normal"
        className={classes.textField}
        helperText="Enter branch url"
        margin="normal"
        onChange={onChangeBranch}
        value={state.branchUrl}
        autoFocus={true}
        error={branchUrlError}
        disabled={saveLoading}
      />
      <TextField
        label="Refresh Token"
        id="margin-normal"
        className={classes.textField}
        helperText="Enter account refresh token"
        margin="normal"
        onChange={onRefreshTokenChange}
        value={state.refreshToken}
        autoFocus={true}
        disabled={saveLoading}
      />
      <TextField
        label="Trello Card Id"
        id="margin-normal"
        className={classes.textField}
        helperText="Enter trello card id"
        margin="normal"
        onChange={onTrelloIdChange}
        value={state.trelloCardId}
        autoFocus={true}
        error={branchUrlError}
        disabled={saveLoading}
      />
      <div className={classes.control}>
        <ProgressButton
          title={"Update"}
          loading={saveLoading}
          onClick={onSubmit}
        />
      </div>
      {!!showMessage && (
        <SnackBar message={message} onClose={() => setShowMessage(false)} />
      )}
    </Paper>
  );
};

export const EditReddit = withRouter(EditC);