import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import Send from '@material-ui/icons/Send';
import {TextField} from "@material-ui/core";

export interface ChipData {
  key: number;
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexFlow: 'column nowrap',
      padding: theme.spacing(0.5),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    inputField: {
      position: 'relative',
      width: '300px',
    },
    list: {
      display: 'flex',
      flexFlow: 'row wrap'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 300,
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    send: {
      cursor: 'pointer',
      position: 'absolute',
      top: 30,
      right: 2,
      transition: 'transform .2s',
      '&:hover': {
        color: "#6f8b77",
        transform: 'scale(1.3)'
      },
    },
  }),
);

interface IProps {
  onChange?(tags: ChipData[]): void;

  error?: boolean;
  loading?: boolean;
  tags: ChipData[];
  required?: boolean;
}

export const TagsInput = (props: IProps) => {
  const {onChange, error, loading, tags, required} = props
  const classes = useStyles();
  const [value, setValue] = React.useState<string>('');
  const onUpdate = (data: ChipData[]) => {
    if (onChange) onChange(data);
  };

  const handleDelete = (chipToDelete: ChipData) => () => {
    if (loading) return;
    const filtered = tags.filter(chip => chip.key !== chipToDelete.key);
    onUpdate(filtered);
  };

  function onChangeInput(e: any) {
    setValue(e.currentTarget.value);
  }

  const onAdd = (e: any) => {

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (!value) return;
      const updatedChips: ChipData[] = [...tags, {key: tags.length, label: value}];
      onUpdate(updatedChips);
      e.preventDefault && e.preventDefault();
      setValue(old => '');
    }
  };


  return (
    <div className={classes.root}>
      <div className={classes.inputField}>
        <TextField
          label="IATAS"
          id="margin-normal"
          className={classes.textField}
          helperText="Enter iata's name"
          margin="normal"
          onKeyPress={onAdd}
          onKeyDown={onAdd}
          autoFocus={true}
          error={error}
          onChange={onChangeInput}
          value={value}
          disabled={loading}
          required={required}
        />

        <Send className={classes.send} onClick={() => onAdd({key: 'Enter'})}/>
      </div>
      <div className={classes.list}>
        {tags.map(data => {
          let icon;

          if (data.label === 'React') {
            icon = <TagFacesIcon/>;
          }

          return (
            <Chip
              key={data.key}
              icon={icon}
              label={data.label}
              onDelete={handleDelete(data)}
              className={classes.chip}
            />
          );
        })}
      </div>
    </div>
  );
};
