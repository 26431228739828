import * as React from 'react';
import moment from 'moment';

const uuidv4 = require('uuid/v4');

export function validURL(str: string) {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

export function uniqueId() {
  return uuidv4();
}

export function dateFormat(datr: any) {
  return moment(datr).format('MMM, D, YYYY, h:mm a')
}

export function issueTypeMapper(type: string) {
  switch (type) {
    case 'PENDING_POST':
      return <p style={{ color: '#2fe4ff' }}>Pending Post</p>;
    case 'PENDING_SETUP':
      return <p style={{ color: '#ffd9ce' }}>Pending Setup</p>;
    case 'LOGIN_FAILED':
      return <p style={{ color: '#ff7070' }}>Login Failed</p>;
    case 'POST_FAILED':
      return <p style={{ color: '#cfcfff' }}>Post Failed</p>;
    default:
      return <p style={{ color: '#1107ff' }}>N/A</p>;
  }
}

export function issueReasonsMapper(type: string) {
  switch (type) {
    case 'EMAIL_VERIFICATION':
      return <p style={{ color: '#2fe4ff' }}>Email Verification</p>;
    case 'PASSWORD_RESET':
      return <p style={{ color: '#ffd9ce' }}>Password Reset</p>;
    case 'MISSING_CAPTION':
      return <p style={{ color: '#ff7070' }}>Missing Caption</p>;
    default:
      return <p style={{ color: '#1107ff' }}>N/A</p>;
  }
}

export const readStorage = function (key: string, isObject: boolean): any {
  const found = window.localStorage.getItem(key);

  if (found) {
    if (isObject) return JSON.parse(found);
    return found;
  }
  return false;
};

export const setStorage = function (key: string, data: any, isObject: boolean): void {
  window.localStorage.setItem(key, isObject ? JSON.stringify(data) : data);
};

export const validSocialURL = function (str: string) {
  // validate domain && confirm it's reddit, instagram or twitter
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  if (!!pattern.test(str)) {
    if (new RegExp(['reddit.com', 'instagram.com', 'twitter.com'].join("|")).test(str)) {
      return true;
    } else {
      return false;
    }
  }
}
export const withHttps = (url: string) => { return !/^https?:\/\//i.test(url) ? `https://${url}` : url; }

interface RTIAccounts {
  rAccs: RedditAccount[],
  tAccs: TwitterAccounts[],
  iAccs: InstagramAccounts[]
}

export const convertAccsToCsv = (accs: RTIAccounts) => {
  // src: https://stackoverflow.com/a/14966131
  let csvContent = "data:text/csv;charset:utf-8,";
  for (let i = 0; i < accs.rAccs.length; i++) {
    let row = `RD,${accs.rAccs[i].url}`
    csvContent += row + "\r\n";
  }
  for (let i = 0; i < accs.tAccs.length; i++) {
    let row = `TW,${accs.tAccs[i].url}`
    csvContent += row + "\r\n";
  }
  return csvContent;
}
